import React from 'react'
import PropTypes from 'prop-types'
import Scribble from '../../../../static/assets/paint-stain.png'

import * as S from './styles'

function Step({ title, description, color, stepNumber }) {
  return (
    <S.Container>
      <S.Image src={Scribble} alt="Imagem simulando rabisco de lápis" />
      <S.StepNumber color={color}>{stepNumber}</S.StepNumber>
      <S.StepTitle>{title}</S.StepTitle>
      <S.StepDescription>{description}</S.StepDescription>
    </S.Container>
  )
}

Step.defaultProps = {
  color: 'rgb(67, 64, 104)'
}

Step.propTypes = {
  stepNumber: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default Step
