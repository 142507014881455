import React, { useState } from 'react'
import { mask as masker, unMask } from 'remask'
import PropTypes from 'prop-types'
import { FirebaseInstance } from '../../service/Firebase'
import { toast } from 'react-toastify'
import { Spinner6 } from '@styled-icons/icomoon/Spinner6'

import Image from '../../images/drawing-footer.png'
import { selectOptions } from './content'

import * as S from './styles'

const InputMask = ({ mask, onChange, value, ...props }) => {
  const handleChange = (ev) => {
    const originalValue = unMask(ev.target.value)
    onChange(originalValue)
  }

  const handleValue = masker(value, mask)

  return <S.InputPhone {...props} onChange={handleChange} value={handleValue} />
}

const ENUM_FIELDS = {
  name: 'Nome',
  email: 'E-Mail',
  phone: 'Telefone',
  collection: 'Coleção'
}

const RGX_PHONE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function SectionContact() {
  const [, setValue] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    collection: ''
  })
  const [sendForm, setSendForm] = useState(false)

  const allFieldsFilled = () => {
    let missingField = false
    Object.entries(formData).forEach((item) => {
      if (
        !item[1] ||
        (item[0] === 'collection' && item[1] === '1') ||
        (item[0] === 'email' && !RGX_PHONE.test(item[1]))
      ) {
        toast.error(
          `Ops...! Falta preencher o campo de ${ENUM_FIELDS[item[0]]}`
        )
        missingField = true
      }
    })

    return missingField
  }

  const handlePhone = (valueParam) => {
    setValue(event)
    setFormData({
      ...formData,
      phone: valueParam
    })
  }

  const handleFormInput = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleContact = () => {
    const firebase = FirebaseInstance

    if (!allFieldsFilled()) {
      setSendForm(true)
      firebase
        .create(formData)
        .then(() => {
          setFormData({
            name: '',
            email: '',
            phone: '',
            collection: '1'
          })
          toast.success('Contato enviado com sucesso!')
        })
        .catch(() => toast.error('Ops...! Algo deu errado!'))
        .finally(() => setSendForm(false))
    }
  }

  return (
    <S.ContactWrapper id="contact-section">
      <S.ContactContainer>
        <S.Title>Ficou interessado em nossas coleções ?</S.Title>
        <S.ContactContent>
          <S.Image
            src={Image}
            alt="Desenho uma mullher mãos levantadas com um coração"
          />
          <S.ContactForm>
            <S.FormTitle>
              Preencha o formulário para receber as melhores ofertas
            </S.FormTitle>
            <S.FormFields>
              <S.InputName
                placeholder="Digite seu nome"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleFormInput}
              />
              <S.InputEmail
                placeholder="Digite seu e-mail"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleFormInput}
              />
              <InputMask
                mask={['(99) 9 9999-9999', '(99) 9999-9999']}
                placeholder="Digite seu celular ou WhatsApp"
                type="phone"
                value={formData.phone}
                name="phone"
                onChange={handlePhone}
              />
              <S.InputCollection
                name="collection"
                onChange={handleFormInput}
                value={formData.collection}
              >
                <S.InputOption value="1">
                  Selecione coleção de interesse
                </S.InputOption>
                {selectOptions.map((collection) => (
                  <S.InputOption value={collection.value} key={collection.id}>
                    {collection.name}
                  </S.InputOption>
                ))}
              </S.InputCollection>
            </S.FormFields>
            <S.ButtonForm onClick={handleContact}>
              {sendForm ? <Spinner6 size={26} fill="#fff" /> : 'Enviar'}
            </S.ButtonForm>
          </S.ContactForm>
        </S.ContactContent>
      </S.ContactContainer>
    </S.ContactWrapper>
  )
}

InputMask.propTypes = {
  mask: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default SectionContact
