import styled from 'styled-components'
import media from 'styled-media-query'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #030517;
  height: 100%;
  max-width: 100%;
  padding: 0.5rem;

  > div.gatsby-image-wrapper {
    min-width: 8rem;
    min-height: 10rem;

    max-width: 10rem;
    max-height: 10rem;

    ${media.lessThan('medium')`
      min-width: 5rem;
      min-height: 7rem;

      width: 5rem;
      height: 7rem;

      max-width: 5rem;
      max-height: 7rem;
    `}
  }
`

export const Image = styled.img`
  width: 12rem;
  height: 12rem;
  margin-bottom: 0.5rem;

  ${media.lessThan('medium')`
    width: 8rem;
    height: 8rem;
  `}
`

export const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ${media.lessThan('medium')`
    max-height: 70%;
  `}
`

export const Title = styled.h1`
  font-size: 1.3rem;
  color: #303049;
  margin-bottom: 0.5rem;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`

export const DescriptionBook = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #434068;
    border-radius: 10px;
  }

  ${media.lessThan('medium')`
    font-size: 12px;
    line-height: 1rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #434068;
      border-radius: 10px;
    }
  `}
`

export const Text = styled.p`
  white-space: pre-line;
  padding: 0 2rem;
  font-size: 16px;
  line-height: 20px;

  ${media.lessThan('medium')`
    padding: 0;
    padding-right: 0.5rem;
    font-size: 14px;
  `}
`
