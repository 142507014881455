import styled from 'styled-components'
import media from 'styled-media-query'

export const SectionCollection = styled.section`
  width: 100%;
  background: #fff;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 1rem;
  background: #fff;
`

export const Title = styled.h1`
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  color: rgb(67, 64, 104);
`

export const Content = styled.section`
  &::before {
    justify-content: center;
    content: '';
    height: 4px;
    background: #303049;
    width: 200px;
    border-radius: 5px;
    position: absolute;
    margin: 0 auto;
    left: -50%;
    right: -50%;
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }

  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slider {
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .slick-list:focus {
    outline: 0;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    top: 0;
    left: 0;
  }

  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-list {
  }

  .slick-dots {
    list-style: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    ${media.lessThan('1045px')`
      margin-top: 0;
    `}

    li {
      background: #303049;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.8rem;
      cursor: pointer;

      ${media.lessThan('1045px')`
        margin: 0 0.3rem;
      `}

      &.slick-active {
        background: #3ad586;
      }
    }

    button {
      opacity: 0;
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
`
