export const engDeProducao = {
  id: 1,
  name: 'Engenharia da Produção',
  featuredPhoto: 'colecaoCompletaEng.jpg',
  text: `Essa coleção conta com 08 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Ergonomia: Trabalho Adequado e Eficiente',
      photo: 'ergonomiaTrabalhoAdequado.jpg',
      description: `
        A Ergonomia tem um nobre propósito: o de melhorar o cotidiano das pessoas em suas casas, ambientes de lazer, no transporte ou no trabalho. Para isso, auxilia no projeto de produtos mais adequados e seguros ao uso e é essencial no projeto da organização, do espaço e do conteúdo do trabalho.

        A Ergonomia parte do princípio de que o ambiente deve ser adaptado às necessidades físicas do trabalhador e não o contrário, oferecendo bem-estar no local onde as pessoas passam a maior parte das suas vidas. Os resultados são, além de saúde, maior eficiência, produtividade e segurança.

        A consequência desse propósito é o aumento dos estudos e do interesse na área. Especialistas aplicam cada vez mais os conhecimentos adquiridos, o tema sustentabilidade nunca esteve tão em voga e as empresas de classe mundial não podem conviver sem levar em conta a Ergonomia.

        Esta disciplina científica, de caráter multidisciplinar e que possibilita a síntese das ciências da saúde, das ciências básicas, bem como das ciências humanas, se exprime nas engenharias, dada sua forte aplicação no planejamento, na logística e na gestão do trabalho.

        Os estudiosos desse campo de conhecimento, e outros interessados no assunto, encontrarão neste livro, estruturado principalmente para cobrir as necessidades de um curso de graduação para engenheiros de produção, uma ampla gama de temas e métodos práticos e aplicados.

        A dimensão resultante dos esforços combinados de tantos autores conceituados fez com que esta obra se candidate a ser uma referência que certamente agradará estudantes e profissionais.
      `
    },
    {
      id: 2,
      name: 'Introdução à Automação para cursps de engenharia e gestão',
      photo: 'introducaoAutomacao.jpg',
      description: `
        Esta obra inédita apresenta os sistemas de automação nos processos produtivos. Trata-se de um material didático de qualidade para cursos de automação oferecidos a vários públicos.

        Para profissionais do ramo, fornece material introdutório em automação. Para não especialistas – incluindo sobretudo engenheiros, administradores e gestores de projetos e operações – oferece visão geral dos sistemas de automação, uma base para a compreensão de seus conceitos e aplicações. Para todos os temas, evidencia a automação integrada aos sistemas de produção.

        A motivação para a elaboração deste texto veio da própria experiência dos autores, tendo sido necessário vários anos para sua construção e maturação. Ao longo do tempo, o conteúdo da disciplina foi sendo ajustado até chegar ao nível suficiente para o aluno compreender os temas críticos e as tecnologias sem a profundidade técnica exigida para dimensionar os sistemas, mas acrescida de habilidades para gerenciá-los de forma integrada com outras disciplinas.

        Foi possível delinear um conteúdo que abrange os vários sistemas de produção – incluindo indústria, serviços e suas diversas faces – e analisar as principais características dos sistemas de automação para dar suporte à sua operação, inclusive o binômio fundamental flexibilidade versus produtividade, sem entrar nos detalhes de projeto dos sistemas em si, questão de competência dos especialistas.
      `
    },
    {
      id: 3,
      name: 'Administração para Engenheiros',
      photo: 'adminParaEngenheiros.jpg',
      description: `
        Este livro foi escrito com uma linguagem direcionada aos estudantes de Engenharia, pois eles têm a necessidade de compreender a formação do pensamento administrativo e, simultaneamente, visualizar a materialização dos princípios e mecanismos. Dessa forma, os capítulos abrangem assuntos como o layout de fábricas, a diversidade de configurações de empresas, as especificidades da pequena empresa e contextos culturais nos quais as teorias surgiram imprescindíveis à formação dos estudantes. Houve também uma preocupação dos autores de incluir roteiros de aprendizado, que explicam diferentes etapas do processo de aprendizagem.

        Além disso, o conteúdo deste livro possui características que o distinguem de um livro-texto comum:
        - baseado na aprendizagem ativa;
        - explora diferentes mecanismos de aprendizagem;
        - permite a compreensão interdependente das ideias fundadoras da formação pensamento administrativo.

        O objetivo instrucional desta obra é que o leitor seja estimulado a pensar de forma independente, para integrar informações e conceitos de diferentes áreas do conhecimento. As ilustrações com o traço à mão enfatizam o desenho como uma segunda linguagem técnica do livro, própria da formação do engenheiro.
      `
    },
    {
      id: 4,
      name: 'Marketing para Cursos de Engenharia',
      photo: 'mktParaEngenharia.jpg',
      description: `
        Analisando as grades dos cursos de Engenharia e a realidade do mercado, os autores Daisy Rebelatto, Enzo Mariano, Flavia Camioto e Leonardo Leite idealizaram esta obra, que traz os principais conceitos de marketing com o diferencial de ter duas linguagem e aplicação voltada para os estudantes de Engenharia, a fim de preencher a lacuna na formação de engenheiros, destacadamente na área de Desenvolvimento de Produtos.

        O conteúdo técnico é apresentado em três partes:
        A Parte I contém a apresentação dos principais conceitos, oportunidades e estratégias de marketing. As etapas para a elaboração e concepção da pesquisa de marketing, bem como as diretrizes para a criação de questionários e as técnicas, procedimentos e considerações estatísticas para determinar a amostra, também são descritos. Nesta etapa, ainda, é apresentada a técnica do incidente crítico (TIC), que busca os extremos positivo e negativo do comportamento em estudo.
        A Parte II discute as técnicas estatísticas, geralmente utilizadas em pesquisas de marketing para a análise de dados. São explicados os procedimentos e a forma de interpretação dos resultados.
        A Parte III apresenta um estudo de caso diferenciado, no qual foi utilizada a técnica do incidente crítico e da análise conjunta, com a estrutura das etapas compatível com uma pesquisa de marketing e de um planejamento experimental, para analisar os fatores intervenientes na adoção de energias mais limpas pelos principais setores industriais do Estado de São Paulo.
      `
    },
    {
      id: 5,
      name: 'Planejamento e Controle da Produção',
      photo: 'planejamentoEControleDeProduc.jpg',
      description: `
        A proposta de abordagem do PCP nesse livro é uma convergência de metodologias de modelagem interpretativa e matemática que permite ao leitor adquirir uma visão sistêmica dos conceitos e pressupostos para compreender as variáveis necessárias para o projeto e operação de sistemas de planejamento e controle de produção.

        A modelagem de processos de PCP permite avaliar a necessidade de otimização de recursos em contraposição ao nível de serviço desejado. Quanto mais se otimizam os recursos, diminui-se a disponibilidade e, consequentemente, o nível de serviço associado a esses recursos.

        No contexto do livro, os capítulos apresentarão inicialmente um modelo conceitual a ser abordado. Em seguida, a discussão de casos específicos a partir dos conceitos e modelos relevantes para a solução do problema, utilizados para propor ações para melhoria da situação inicial.

        A modelagem auxilia o conhecimento das consequências das ações e decisões como visão estratégica da empresa e estabelece o elo entre o intuitivo e o racional.

        Esse livro pode ser utilizado como livro-texto em cursos de Engenharia de Produção, Bacharelado em Sistemas de Informação e Administração de Empresas.
      `
    },
    {
      id: 6,
      name: 'Cálculo a uma Variável. Volume 1',
      photo: 'calcAUmaVariavelVol1.jpg',
      description: `
        Cálculo a uma variável traz uma nova proposta para o aprendizado do cálculo na universidade.

        A principal inovação é a utilização de sequências de números reais para introduzir o conceito de limite de funções, conceito-base do conteúdo do cálculo diferencial e integral.

        O primeiro volume, Uma Introdução ao Cálculo, se constitui num programa inovador de preparação para o cálculo diferencial e integral, a ser tratado no segundo volume, Derivada e Integral.
      `
    },
    {
      id: 7,
      name: 'Cálculo a uma Variável. Volume 2',
      photo: 'calcAUmaVariavelVol2.jpg',
      description: `
        Cálculo a uma variável traz uma nova proposta para o aprendizado do cálculo na universidade.

        A principal inovação é a utilização de sequências de números reais para introduzir o conceito de limite de funções, conceito-base do conteúdo do cálculo diferencial e integral.

        O primeiro volume, Uma introdução ao cálculo, se constitui num programa inovador de preparação para o cálculo diferencial e integral, a ser tratado no segundo volume, Derivada e integral.
      `
    },
    {
      id: 8,
      name:
        'Metodologia de Pesquisa em Engenharia de Produção e Gestão de Operações',
      photo: 'metodologiaDePesquisa.jpg',
      description: `
        Esta segunda edição do livro Metodologia de Pesquisa em Engenharia de Produção e Gestão de Operações representa mais um passo importante no sentido do amadurecimento e consolidação da engenharia de produção como área de pesquisa relevante no país.

        O bom uso de metodologia é condição essencial para que os esforços de pesquisa de fato contribuam, sobre bases científicas sólidas, para a expansão do conhecimento que, em última análise, vai contribuir com o desenvolvimento de processos produtivos melhores, mais eficientes e sustentáveis e, por conseguinte, com a construção de uma sociedade melhor e mais justa.

        Embora esse esforço de formação de pesquisadores em engenharia de produção mais bem equipados do ponto de vista metodológico esteja em constante evolução no país, ainda havia uma lacuna importante: a inexistência até agora de um manual, um livro que copilasse as principais abordagens e técnicas metodológicas quantitativas, qualitativas e híbridas, tendo como foco específico a pesquisa em engenharia de produção.

        Este livro contribui substancialmente para preencher essa lacuna. É leitura obrigatória para estudantes, professores e pesquisadores que pretendam dar uma contribuição mais efetiva para a expansão do conhecimento na área de engenharia de produção e, claro, como consequência, ter suas pesquisas publicadas nos melhores periódicos do mundo.
      `
    }
  ]
}
