// const replaceSpacesToLink = (message) => {
//   return message.replace(/\s+/g, '%20')
// }

const createLinkWhatsapp = (numberPhone, message = '') => {
  // const formatedString = replaceSpacesToLink(message)
  const url = `https://wa.me/${numberPhone}`

  return url
}

export const links = [
  {
    label: 'Whatsapp',
    url: createLinkWhatsapp('5518996362183', ``),
    color: '#00E676'
  },
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/natureza.juridica.direito',
    color: '#0582ED'
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/natureza.juridica/',
    color: '#DB7B86'
  }
]
