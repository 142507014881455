export const selectOptions = [
  {
    id: 1,
    value: 'arquitetura',
    name: 'Arquitetura'
  },
  {
    id: 2,
    value: 'direito',
    name: 'Direito'
  },
  {
    id: 3,
    value: 'edFisicaBacharel',
    name: 'Ed. Física Bacharel'
  },
  {
    id: 4,
    value: 'edFisicaLicenciatura',
    name: 'Ed. Física Licenciatura'
  },
  {
    id: 5,
    value: 'farmacia',
    name: 'Farmácia'
  },
  {
    id: 6,
    value: 'fundamentosEngenharia',
    name: 'Fundamentos de Engenharia'
  },
  {
    id: 7,
    value: 'engProducao',
    name: 'Engenharia da Produção'
  },
  {
    id: 8,
    value: 'livrosDeEngenharia',
    name: 'Livros de Engenharia'
  },
  {
    id: 9,
    value: 'livrosDeAdministracao',
    name: 'Coleção de Livros Administração'
  }
]
