import React, { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import PropTypes from 'prop-types'

import Image from '../Image'

import * as S from './styles'

const ReviewCard = ({ photoItem, fnSetModalDetailProduct }) => {
  useEffect(() => {
    const texts = document.querySelectorAll('p.description')

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        entry.target.classList[
          entry.target.scrollHeight > entry.contentRect.height + 25
            ? 'add'
            : 'remove'
        ]('truncated')
      }
    })

    texts.forEach((text) => observer.observe(text))
  })

  return (
    <S.Card>
      <S.User>
        <Image filename={photoItem.featuredPhoto} alt={photoItem.name} />
        <S.Name>{photoItem.name}</S.Name>
      </S.User>
      <S.Text>
        <p className="description">{photoItem.text}</p>
      </S.Text>
      <S.Details onClick={() => fnSetModalDetailProduct(true, photoItem)}>
        Veja + detalhes
      </S.Details>
    </S.Card>
  )
}

ReviewCard.propTypes = {
  photoItem: PropTypes.object.isRequired,
  fnSetModalDetailProduct: PropTypes.func.isRequired
}

export default ReviewCard
