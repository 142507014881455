import React, { useState, useEffect } from 'react'

import SEO from '../components/seo'

import Header from '../components/Header'
import Layout from '../components/Layout'
import SectionCollections from '../components/sectionCollections'
import SectionSteps from '../components/sectionSteps'
import SectionContact from '../components/sectionContact'
import SectionFooter from '../components/sectionFooter'
import ModalMenuPhone from '../components/ModalMenuPhone'
import ModalProductDetails from '../components/ModalProductDetails'

const IndexPage = () => {
  const [menuPhone, setMenuPhone] = useState(false)
  const [modalDetailProduct, setModalDetailProduct] = useState(false)
  const [collectionSelected, setCollectionSelected] = useState(undefined)

  const handleModalMenu = (state) => {
    setMenuPhone(state)
    handleOverflowBody(state)
  }

  const handleModalProduct = (state, collectionSelected) => {
    setCollectionSelected(collectionSelected)
    setModalDetailProduct(state)
    handleOverflowBody(state)
  }

  const handleOverflowBody = (action) => {
    action
      ? (document.getElementsByTagName('body')[0].style.overflowY = 'hidden')
      : (document.getElementsByTagName('body')[0].style.overflowY = 'auto')
  }

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflowX = 'hidden'
    setMenuPhone(false)
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <Header fnSetMenuPhone={handleModalMenu} menuOpened={menuPhone} />
      <SectionCollections fnSetModalDetailProduct={handleModalProduct} />
      <SectionSteps />
      <SectionContact />
      <SectionFooter />
      <ModalMenuPhone
        menuPhone={menuPhone}
        fnHandleModalMenu={handleModalMenu}
      />
      {modalDetailProduct && (
        <ModalProductDetails
          fnSetModalDetailProduct={handleModalProduct}
          menuOpened={modalDetailProduct}
          collectionSelected={collectionSelected}
        />
      )}
    </Layout>
  )
}

export default IndexPage
