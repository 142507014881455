export const farmacia = {
  id: 1,
  name: 'Farmácia',
  featuredPhoto: 'colecaoCompletaFarm.jpg',
  text: `Essa coleção conta com 08 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Da Química Medicinal à Química Combinatória e Modelagem Molecular',
      photo: 'daQuimicaMedicinalAQuimicaCombinatoria.jpg',
      description: `
        Da Química Medicinal á Química Combinatória e Modelagem Molecular, agora em sua 2ª edição revisada e ampliada, escrito por professores universitários, é um curso prático de química farmacêutica, que abrange desde o desenvolvimento de fármacos até a sua utilização.

        De forma clara e objetiva, são abordados métodos de separação e purificação de fármacos, determinação das constantes físicas, sínteses tradicionais, determinação estrutural, modelagem molecular, química combinatória e reações químicas de biotransformação.

        Com este livro, docentes, alunos e técnicos de Farmácia e Bioquímica, Química, Engenharia Química, Biomedicina, Microbiologia Aplicada e Práticas Computacionais terão a oportunidade de conhecer as técnicas mais modernas e obrigatórias de manuseio de fármacos, como complemento das práticas tradicionais de laboratório.
      `
    },
    {
      id: 2,
      name: 'Fármacia Clínica',
      photo: 'farmaciaClinica.jpg',
      description: `
        O Instituto Israelita de Ensino e Pesquisa Albert Einstein oferece cursos de especialização lato sensu para diversas áreas da medicina, biomedicina, enfermagem, farmácia, fisioterapia, psicologia, entre outras, ampliando o diferencial competitivo do Hospital no quadro das instituições de saúde de vanguarda no Brasil e no exterior.

        A série de Manuais de Especialização Einstein, destinada a graduandos e pós-graduandos, traz a experiência acumulada de profissionais e especialistas das diferentes áreas, interfaces e disciplinas que compõem seus cursos de especialização e de instituições renomadas no país.

        O sétimo manual, Farmácia Clínica, começa pela abordagem dos conceitos de farmácia, farmacologia e farmacocinética clínicas e continua por temas de grande importância, como dispensação, automação, farmacovigilância, farmacoeconomia e qualidade em farmácia. Em seguida, concentra-se no controle de infecção hospitalar, nos antibióticos e na resistência bacteriana, além de tratar das unidades de terapia intensiva e, por fim, discorrer sobre avaliação tecnológica, transplante, oncologia, geriatria, pneumologia e diabete, contribuindo para a área da farmácia clínica dentro do contexto hospitalar.

        Esta obra reflete o objetivo principal da série, que é contribuir com temas estratégicos na transmissão do conhecimento científico atualizado, ao acompanhar as transformações das ciências da saúde e promover um atendimento intra-hospitalar humanizado, de qualidade e que possa ser divulgado para diferentes serviços e áreas de atuação.
      `
    },
    {
      id: 3,
      name: 'Farmacêutico Hospitalar',
      photo: 'farmaceuticoHospitalar.jpg',
      description: `
        O livro Farmacêutico hospitalar: conhecimentos, habilidades e atitudes contou com a participação de mais de quarenta profissionais, grandes nomes de várias gerações da farmácia hospitalar brasileira, e busca contribuir com a qualificação do profissional farmacêutico hospitalar e, consequentemente, com o fortalecimento desta categoria, considerando o cenário atual do mercado de trabalho.

        A obra destina-se a estudantes e farmacêuticos que desejam atuar ou já atuam em farmácias hospitalares e de demais serviços de saúde.

        Os capítulos ainda trazem em seu conteúdo orientações a respeito das competências:

        • de ensino, capacitação profissional e pesquisa clínica;
        • técnicas de gestão em farmácia;
        • administrativas;
        • de gerenciamento organizacional;
        • políticas institucionais;
        • práticas de relacionamento com o paciente e com outros profissionais de saúde.

        Portanto, a obra Farmacêutico hospitalar: conhecimentos, habilidades e atitudes preenche uma lacuna na literatura profissional, essencial para profissionais e estudantes de farmácia.
      `
    },
    {
      id: 4,
      name: 'Farmácia Clínica & Atenção Farmacêutica',
      photo: 'farmaciaClinicaEAtencao.jpg',
      description: `
        A farmácia clínica busca a melhoria dos resultados farmacoterapêuticos e comprovadamente aumenta a adesão do paciente ao tratamento, diminui custos nos sistemas de saúde quando monitora reações adversas e interações medicamentosas, e melhora a qualidade de vida dos pacientes.

        Farmácia Clínica & Atenção Farmacêutica tem como objetivo principal fornecer ao profissional e ao acadêmico as ferramentas básicas de trabalho - teóricas, práticas e clínicas - para o exercício da farmácia clínica e a reflexão sobre a prática clínica no ambiente altamente competitivo da era globalizada.

        A obra é organizada em três partes, contendo:
        • os príncipios de farmácia clínica e atenção farmacêutica;
        • as ferramentas para o trabalho diário e as atividades práticas clínicas;
        • informações sobre grupos específicos de pacientes.

        Farmácia Clínica & Atenção Farmacêutica constitui uma fonte valiosa para o aprendizado do assunto e a consulta no dia a dia profissional.
      `
    },
    {
      id: 5,
      name: 'Farmácia Homeopática',
      photo: 'farmaciaHomeopatica.jpg',
      description: `
        Farmácia Homeopática: Teoria e Prática é um livro de excepcional importância para os estudantes e praticantes de homeopatia na área farmacêutica, que necessitam de uma obra atualizada e com finalidade didática-operacional.

        Neste livro, o autor, com a colaboração de importântes nomes do cenário homeopático nacional, combina teoria e prática e trata dos conceitos mais relevantes da farmácia homeopática, desde os princípios e fundamentos da homeopatia, passando pela farmacologia, farmacotécnica e legislação homeopáticas, até os procedimentos de qualidade em farmácia homeopática e o resumo das monografias dos principais medicamentos.

        Esta quarta edição repete os objetivos das anteriores, mas também agrega novos conhecimentos e atualiza as leis na área da farmácia homeopática.
      `
    },
    {
      id: 6,
      name:
        'Controle Biológico de Qualidade de Produtos Farmacêuticos, Correlatos e Cométicos',
      photo: 'controleBiologico.jpg',
      description: `
        O presente livro é fruto de décadas de trabalho frente á disciplina de Controle Biológico de Qualidade de Medicamentos e Cosméticos da Faculdade de Ciências Farmacêuticas da Universidade de São Paulo (USP), quando havia dificuldades na indicação de uma bibliografia, preferencialmente em português, que contemplasse os tópicos básicos da disciplina.

        Assim nasceu esta obra, cuja organização dos capítulos apresentados contempla aspectos conceituais de qualidade aplicáveis a produtos, sistemas e processos, tanto gerais quanto no âmbito biológico. A pretensão dos autores não foi a decriar uma obra completa sobre controle de qualidade ou decontrole biológico de qualidade de medicamentos e cosméticos, mas sim de possibilitar acesso a um livro fácil, conciso e atual, que contribua para o ensino nas faculdades de Farmácia do país.
      `
    },
    {
      id: 7,
      name: 'Fundamentos de Patologia',
      photo: 'funcamentosDaPatologia.jpg',
      description: `
        Esta edição, que oferece acesso rápido e fácil aos fatos e conceitos mais importantes da patologia, refina os conceitos chave e os princípios de patologia de forma condensada, o que torna o seu tamanho a referência perfeita para uma rápida consulta com um só olhar!

        Revisão para os exames com conteúdo que destaca o material mais importante da edição atual de Robbins & Cotran – Fundamentos de Patologia.

        Acesso fácil ás informações com um conteúdo que segue fielmente o sumário da atual edição do livro principal.

        Acesso aos conceitos-chave e princípios de patologia em um formato condensado para rápida consulta que cabe em seu bolso

        Tradução da 9ª Edição.
      `
    },
    {
      id: 8,
      name: 'Dicionário Trilíngue da Saúde',
      photo: 'dicionarioTrilingue.jpg',
      description: `
        Com o DICIONÁRIO TRILÍNGUE DA SAÚDE, o usuário tem ao seu alcance a possibilidade de conhecer uma seleção de 2000 termos e expressões fundamentais do âmbito médico e da saúde em português, inglês e espanhol.

        O objetivo deste dicionário é ser um glossário informativo, de manejo fácil e prático, destinado a médicos e outros profissionais da saúde pública, assim como a estudantes ou pesquisadores das áreas médicas.
      `
    }
  ]
}
