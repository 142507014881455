export const arquiteturaPhotos = {
  id: 1,
  name: 'Arquitetura',
  featuredPhoto: 'colecaoCompletaArq.jpg',
  text: `Essa coleção conta com 09 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Manual do Arquiteto',
      photo: 'manualDoArquiteto.jpg',
      description: `
        Manual do Arquiteto - Planejamento, Dimensionamento e Projeto é o ponto de partida ideal para qualquer projeto de arquitetura. Além das tipologias de edificação, trata de aspectos específicos do projeto, como materiais, acústica e iluminação, de dados gerais de projeto sobre as dimensões humanas (ergonomia e ergometria) e de necessidades espaciais. A obra fornece as exigências básicas para o projeto de cada tipo de edificação, bem como os principais dados dimensionais e um conjunto de orientações sucintas sobre como usar tais informações. Muitas atualizações foram incorporadas ao texto, refletindo, nos projetos, as mudanças de comportamento e necessidades da sociedade.

        NOVIDADES

        • Capítulos profundamente revisados, incluindo habitações, transportes, escritórios, bibliotecas e hotéis

        • Inclusão de capítulo sobre o projeto em áreas sujeitas a enchentes, em resposta ao aumento do risco de inundações em consequência das alterações climáticas

        • As práticas de projeto sustentável foram integradas a todos os capítulos

        Esta nova edição é o texto mais atualizado do mercado e um investimento obrigatório para todos os escritórios de projeto, bibliotecas e estudantes de arquitetura.
      `
    },
    {
      id: 2,
      name: 'Representação Gráfica em Arquitetura',
      photo: 'representacaoGrafica.jpg',
      description: `
      COMO TRADUZIR IDEIAS DE ARQUITETURA EM IMAGENS

      Representação Gráfica em Arquitetura é a clássica introdução ás ferramentas, aos princípios e ás técnicas essenciais que os arquitetos utilizam para traduzir ideias de projeto em apresentações visuais.

      Destaques desta edição:
      • Um capítulo totalmente atualizado e expandido sobre desenho á mão livre, que destaca técnicas de representação gráfica in loco, incluindo o desenho a partir da observação, os temas de desenho, a composição e os esboços de viagem
      • Informações extras sobre projeções ortogonais em relação a modelos tridimensionais
      • Exemplos de móveis modernos e leiautes de apresentação contemporâneos
      • Explicações revisadas sobre pesos de linha, dimensionamento e desenhos em perspectiva
      • Centenas de desenhos no estilo pessoal de Francis D.K. Ching

      Recomendado tanto para profissionais como para estudantes e amadores da Arquitetura, este livro apresenta os complexos conceitos de arquitetura de uma maneira gráfica clara e eficiente.
      `
    },
    {
      id: 3,
      name: 'Arquitetura de Interiores Ilustrada',
      photo: 'interioresIlustrada.jpg',
      description: `
        Esta obra é uma introdução completa ao projeto de interiores, abordando as exigências de arquitetura dos espaços internos e os detalhes dos móveis e acabamentos. Muito prática e ricamente ilustrada, esta nova edição apresenta importantes conceitos, de forma concisa e acessível. O conteúdo foi desenvolvido para os estudantes, mas os arquitetos experientes também apreciarão a cobertura inteligente e prática das relações entre as edificações como um todo e os espaços internos. Uma ampla variedade de ótimas ilustrações traz informações visuais e inspira ideias criativas.
        O papel da cor na arquitetura de interiores tem destaque em páginas coloridas e novos tópicos foram acrescentados, como:

        • Sustentabilidade
        • Qualidade do ar dos interiores, sistemas de climatização, comunicação e transmissão de dados, segurança
        • A tradução das necessidades funcionais em decisões de projeto
        • A integração de sistemas hidrossanitários de climatização, saúde, segurança e conforto.
      `
    },
    {
      id: 4,
      name: 'Desenho para Arquitetos',
      photo: 'desenhoParaArquitetos.jpg',
      description: `
        Francis D.K.Ching, professor de arquitetura e escritor de renome internacional, mais uma vez nos leva a uma fascinante viagem ao processo de criação por meio de seu estilo de representação gráfica único. Em Desenho para Arquitetos, segunda edição, ele revela os processos cognitivos básicos que governam a percepção e a expressão visual, incluindo observação, memória e representação.
        Esta obra une a visão imaginativa aos princípios de arquitetura fundamental para abordar os aspectos básicos do desenho, examina os diferentes tipos de recursos e estratégias de desenho técnico e mostra como eles podem ser aplicados para obter resultados espetaculares. Além disso, Desenho para Arquitetos:

        • Vai além dos manuais de desenho básicos - Ching não somente aborda os princípios, os meios e as técnicas de desenho, como os insere no contexto dos temas dos projetistas e das razões pelas quais eles desenham.

        • Apresenta mais de 1.500 desenhos feitos á mão - belas ilustrações que reforçam os conceitos e as lições de cada capítulo.

        • Inclui um CD-ROM em inglês: animação, vídeos e maquetes eletrônicas, além de 12 módulos com vídeos do autor demonstrando suas técnicas de desenho á mão livre passo a passo complementam o conteúdo do livro.

        Destinado a arquitetos e projetistas, desenhistas, artistas em geral, ilustradores, professores e estudantes, Desenho para Arquitetos é uma ferramenta eficaz e devalor inestimável, demonstrando conceitos e técnicas por meio de um formato visualmente estimulante.
      `
    },
    {
      id: 5,
      name: 'Técnicas de Construção Ilustradas',
      photo: 'tecnicaDeConstrucaoIlustrada.jpg',
      description: `
        O GUIA VISUAL CLÁSSICO PARA A BASE DAS TÉCNICAS DE CONSTRUÇÃO!

        Uma excelente introdução, pelas mãos do mestre Ching, aos princípios que fundamentam as técnicas de construção. A nova edição deste clássico reafirma sua relevância, combinando conteúdo aprofundado e ilustrações extraordinárias. O autor também manteve seu compromisso com a sustentabilidade, incorporando princípios que reduzem a dependência de sistemas de controle ambiental e a energia por eles consumida.

        Destaques

        • Informação introdutória fundamental sobre materiais e métodos construtivos tópicos imprescindíveis na formação do arquiteto, projetista, técnico em construção e administradores.
        • Desenhos claros e detalhados que representam o estado da arte em processos e materiais de construção - atualizados e revisados para incluir os mais recentes conhecimentos sobre sustentabilidade, sistemas de construção e novos materiais.

        Técnicas de Construção Ilustradas, Quinta Edição, é indicado para aqueles que desejam adquirir conhecimento além do básico e para aqueles que buscam ideias inspiradoras para seus projetos.
      `
    },
    {
      id: 6,
      name: 'Edificações Sustentáveis Ilustradas',
      photo: 'edificacaoSustentavel.jpg',
      description: `
        COMO TORNAR REALIDADE A PROMESSA DE UMA EDIFICAÇÃO SUSTENTÁVEL

        Seguindo a tradição dos best-sellers de Francis D.K. Ching, esta obra escrita em parceria com Ian M. Shapiro explica as questões fundamentais do projeto e da construção sustentáveis em um formato ricamente ilustrado.

        O livro propõe uma abordagem ao projeto do exterior para o interior, cobrindo os inúmeros aspectos da sustentabilidade e oferecendo uma estrutura de trabalho e estratégias detalhadas intrinsecamente ecológicas. Explica por que precisamos de construções sustentáveis, quais são as teorias por trás disso e os novos rumos dessa prática. Edificações Sustentáveis Ilustradas também discute temas centrais como a seleção do terreno, as estratégias passivas de projeto que tiram partido do formato das edificações, da conservação da água, da ventilação e climatização naturais, e os materiais que têm menores impactos sobre o meio ambiente.

        As ilustrações características de Ching estão em todas as páginas da obra e a tornam um manual de referência indispensável para a arquitetura e a construção sustentável.
      `
    },
    {
      id: 7,
      name: 'Projeto de Iluminação',
      photo: 'projetoDeIluminacao.jpg',
      description: `
        Este livro fornece o conhecimento necessário sobre iluminação para arquitetos, designers de interiores, consultores de iluminação e estudantes de arquitetura e design de interiores. Dividido em três partes (Fundamentos, Projeto e Aplicações), o texto foi escrito de forma acessível para os iniciantes no assunto e útil para os mais experientes. Traz os conceitos e as estratégias para um projeto de iluminação, exemplos e projetos reais, reunindo o conhecimento técnico de iluminação sob o ponto de vista da arquitetura. Em um texto abrangente, trata de temas desde a criatividade visual até a física aplicada.

        Algumas características desta edição:
        • As aberturas dos capítulos ilustram como a luz se comporta em edificações e espaços urbanos
        • Ênfase na criatividade no projeto de iluminação sob o ponto de vista da arquitetura
        • Abordagem de projeto focada nas necessidades e nos desejos dos usuários
        • Forte ênfase no projeto sustentável e na economia de energia, com dados e exemplos
        • Integração da iluminação natural e elétrica, e da iluminação com outros fatores ambientais
        • Atualização da tecnologia de iluminação e lâmpadas
        • Apresentação das ferramentas necessárias para a prática do projeto criativo
        • Guia para o uso de computadores e instruções passo a passo para calculadoras manuais
        • Exemplos de designers de iluminação internacionalmente renomados.
      `
    },
    {
      id: 8,
      name: 'Sistemas Estruturais Ilustrados',
      photo: 'sistemasEstruturaisIlustrados.jpg',
      description: `
        O LIVRO DE CONSULTA SOBRE O PROJETO DE ESTRUTUAS!

        Um dos conceitos mais difíceis para os arquitetos é o relacionado a um elemento essencial do processo de projeto: a estrutura. Mesmo sem a responsabilidade de desenvolver a estrutura detalhada, o arquiteto precisa conhecer a teoria e a análise das estruturas para projetar um prédio. Sistemas Estruturais Ilustrados, Segunda Edição, segue uma abordagem única: mostra como os sistemas estruturais de uma edificação - como a integração dos componentes com o padrão, as proporções e a escala - se relacionam com aspectos fundamentais do projeto de arquitetura.

        Este guia trata o desenho das estruturas como parte de um projeto e traça um panorama do desenvolvimento histórico dos materiais e da estrutura das edificações.
        Esta nova edição apresenta:

        • Uma cobertura completa das questões de composição formal e espacial
        • Adequação da estrutura ao programa de necessidades
        • Coordenação com as instalações e as normas legais

        Com belas ilustrações em todas as páginas, no estilo característico do professor Ching, Sistemas Estruturais Ilustrados, Segunda Edição, capacita estudantes e profissionais a tomar decisões bem embasadas no projeto de arquitetura.
      `
    },
    {
      id: 9,
      name: 'Introdução à Arquitetura',
      photo: 'introducaoArquitetura.jpg',
      description: `
        UMA PESQUISA COMPLETA SOBRE O AMBIENTE CONSTRUÍDO RESUMIDA EM UM VOLUME

        Introdução á Arquitetura apresenta os textos e os desenhos mais importantes de Francis D.K. Ching aos iniciantes na arquitetura e no projeto. Exibindo sua típica abordagem, extremamente gráfica, esta é a primeira obra introdutória do autor a fazer uma retrospectiva do projeto de espaços, edificações e cidades.

        Os leitores poderão explorar a história e as teorias da arquitetura, os elementos e os processos de projeto e os aspectos técnicos da profissão na atualidade.
        Alguns dos temas apresentados são:

        • Princípios fundamentais de projeto
        • Tipos, sistemas e componentes que influenciam o projeto de arquitetura
        • Ferramentas e técnicas para a geração de idéias
        • Propriedades, características e comportamento dos materiais
        • Sistemas estruturais
        • Sistemas de edificação
        • A prática da arquitetura e a comunicação com o cliente
        • Arquitetura de interiores e urbanismo

        Este é um guia completo e ricamente ilustrado para aqueles que estão iniciando sua carreira na arquitetura ou para os apaixonados por desenho e projeto de arquitetura.
      `
    }
  ]
}
