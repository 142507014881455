import React from 'react'

import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp'
import { Books } from '@styled-icons/icomoon/Books'

import * as S from './styles'

import { links } from './links'

const SectionFooter = () => {
  return (
    <S.FooterWrapper>
      <S.FooterContainer>
        <S.FooterActions>
          <S.FooterBottonWhatsApp href={links[0].url} target="__blank">
            <Whatsapp size={65} fill="#FFF" />
            Fale conosco via WhatsApp
          </S.FooterBottonWhatsApp>
          <S.FooterBottonBuyNow href={links[0].url} target="__blank">
            <Books size={65} fill="#FFF" />
            Compre agora a sua coleção
          </S.FooterBottonBuyNow>
        </S.FooterActions>

        <S.FooterCopywight>
          <S.FooterSiteBrand>
            Copyright © 2020 Educação em Foco comércio de livros LTDA
          </S.FooterSiteBrand>
          <S.FooterSiteDev>
            <a href="mailto:david_borelli@live.com">© 2020 DAVID BORELLI.</a>{' '}
            CRIAÇÃO E DESENVOLVIMENTO DE SITES
          </S.FooterSiteDev>
        </S.FooterCopywight>
      </S.FooterContainer>
    </S.FooterWrapper>
  )
}

export default SectionFooter
