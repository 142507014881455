export const fundamentosDeEngenharia = {
  id: 1,
  name: 'Fundamentos de Engenharia',
  featuredPhoto: 'colecaoCompletaFundEng.jpg',
  text: `Essa coleção conta com 07 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Cálculo 1',
      photo: 'calculo1.jpg',
      description: `
        Cálculo, 10.ed. continua evoluindo para atender ás necessidades de professores e alunos.

        Esta nova edição mantém os pontos fortes das edições anteriores, como a clareza na exposição (marga registrada de Anton), a pedagogia eficaz, representações visuais e o estabelecimento da relação com o mundo real e com a própria experiência do aluno (nos exemplos e exercícios), buscando a compreensão sem sacrificar a precisão matemática. Foram introduzidos novos conjuntos de exercícios, que ajudarão os alunos a melhorar a sua resolução de problemas pela prática.
      `
    },
    {
      id: 2,
      name: 'Cálculo 2',
      photo: 'calculo2.jpg',
      description: `
        Cálculo, 10.ed. continua evoluindo para atender ás necessidades de professores e alunos. Esta nova edição mantém os pontos fortes das edições anteriores, como a clareza na exposição (marga registrada de Anton), a pedagogia eficaz, representações visuais e o estabelecimento da relação com o mundo real e com a própria experiência do aluno (nos exemplos e exercícios), buscando a compreensão sem sacrificar a precisão matemática. Foram introduzidos novos conjuntos de exercícios, que ajudarão os alunos a melhorar a sua resolução de problemas pela prática.
      `
    },
    {
      id: 3,
      name: 'Física para Universitários. Mecânica',
      photo: 'fisicaMecanica.jpg',
      description: `
        Física para Universitários utiliza discussões de pesquisas contemporâneas e de vários tópicos de energia para apresentar a física como uma ciência dinâmica e instigante, com um enorme impacto em todas as outras áreas da ciência. Além de mostrar o empolgante mundo da física, Bauer, Westfall & Dias utilizam um método inédito de resolução de problemas com sete passos para propiciar aos estudantes uma das grandes habilidades que eles devem desenvolver em um curso de física: a capacidade de resolver problemas e pensar logicamente sobre uma situação.

        MECÂNICA

        O primeiro livro de Bauer, Westfall & Dias, Mecânica, descreve e explica cuidadosamente inúmeros tópicos, entre eles: o movimento de translação de uma partícula em várias dimensões, o conceito de força, de energia cinética e potencial, de quantidade de movimento, de trabalho, de colisões, o movimento de rotação de um corpo rígido, equilíbrio estático e a gravitação, entre outros. A apresentação desse conteúdo é feita mostrando como todos esses assuntos estão intimamente conectados com os maiores avanços da física atual. O texto, muito bem-trabalhado, é acompanhado de inúmeras imagens, figuras, exercícios, problemas e exemplos que envolvem e motivam o estudante universitário com as maravilhas da ciência, da tecnologia e da inovação – assuntos atuais e de extrema importância.
      `
    },
    {
      id: 4,
      name: 'Física para Universitários. Eletricidade e Magnetismo',
      photo: 'fisicaEletricidadeEMagnetismo.jpg',
      description: `
        Física para Universitários utiliza discussões de pesquisas contemporâneas e de vários tópicos de energia para apresentar a física como uma ciência dinâmica e instigante, com um enorme impacto em todas as outras áreas da ciência. Além de mostrar o empolgante mundo da física, Bauer, Westfall & Dias utilizam um método inédito de resolução de problemas com sete passos para propiciar aos estudantes uma das grandes habilidades que eles devem desenvolver em um curso de física: a capacidade de resolver problemas e pensar logicamente sobre uma situação.

        ELETRICIDADE E MAGNETISMO

        O segundo livro de Bauer, Westfall & Dias, Eletricidade e Magnetismo, descreve e explica cuidadosamente inúmeros tópicos, entre eles: as propriedades da carga elétrica, o campo elétrico e a lei de Gauss, o potencial elétrico, a capacitância, correntes e resistências elétricas, circuitos de corrente contínua, magnetismo, campos magnéticos produzidos por correntes elétricas, indução eletromagnética, correntes e oscilações eletromagnéticas, ondas eletromagnéticas, entre outros. A apresentação desse conteúdo é feita mostrando como todos esses assuntos estão intimamente conectados com os maiores avanços da física atual. O texto, muito bem-trabalhado, é acompanhado de inúmeras imagens, figuras, exercícios, problemas e exemplos que envolvem e motivam o estudante universitário com as maravilhas da ciência, da tecnologia e da inovação – assuntos atuais e de extrema importância.
      `
    },
    {
      id: 5,
      name: 'Introdução à Engenharia',
      photo: 'introducaoEnhenharia.jpg',
      description: `
        A maior parte das pessoas não tem uma idéia clara do que é a engenharia, do que fazem os engenheiros ou por que eles são tão importantes para a sociedade.

        Este livro foi preparado para destacar a importância dessa atividade e dos seus profissionais. Concebido como livro-texto para os alunos do primeiro semestre de todas as engenharias, que têm essa como disciplina obrigatória do currículo, o material oferece uma abordagem perfeitamente adequada á realidade das escolas brasileiras.

        Com muitos anos de experiência em sala de aula, Luis Fernando Espinosa Cocian procura responder a dúvidas típicas dos estudantes: áreas de atuação do engenheiro, perspectivas de carreira, mercado de trabalho, necessidade de pós-graduação. E vai muito além, discorrendo sobre as diferentes especialidades, os métodos de resolução de problemas, as habilidades necessárias ao sucesso na carreira e os valores éticos da profissão.
      `
    },
    {
      id: 6,
      name: 'Ciência da Computação',
      photo: 'cienciaDaComputacao.jpg',
      description: `
        Este livro oferece uma cobertura ampla e uma clara exposição do vasto e dinâmico campo da ciência da computação.

        Programação, navegação na Web e compartilhamento de arquivos na Internet são apenas uma pequena parte dele.
        Iniciando por esses tópicos e respondendo a perguntas que todos já se fizeram, Glenn Brookshear apresenta a disciplina como prática e não teórica.
        A partir daí, progride para questões mais abstratas, como estrutura algorítmica, metodologia de desenvolvimento de complexidade, etc.

        A décima primeira edição conta com a contribuição de dois novos autores (David Smith e Dennis Brylow), exemplos novos e modernos, e atualizações com base na tecnologia atual, como os smartphones.

        Tópicos específicos incluem:

        • Hardware para smartphones
        • A distinção entre redes 3G e 4G
        • Sistemas operacionais para smartphones
        • Desenvolvimento de software para smartphones
        • A interface entre humanos e smartphones

        Escrito em linguagem acessível a alunos de todos os cursos, o texto incentiva o desenvolvimento de uma compreensão prática e realista da área.
        Pode ser utilizado em disciplinas introdutórias dos cursos de Ciência da Computação, Sistemas de Informação e Engenharia da Computação, bem como em cursos que tenham uma disciplina de Introdução á Informática/Computação.
      `
    },
    {
      id: 7,
      name: 'Química Geral',
      photo: 'quimicaGeral.jpg',
      description: `
        Mais de 40 milhões de estudantes confiaram na Coleção Schaum para ajudá-los a entender e exercitar a matéria estudada.
        Cada livro desenvolve passo a passo todo o conteúdo essencial da disciplina, de uma forma muito didática.
        Inúmeros exemplos, problemas resolvidos e exercícios práticos para testar as habilidades adquiridas.
      `
    }
  ]
}
