import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Image from '../../images/logo.png'

import Spot from '../../images/spot.png'
import DrawingHeader from '../../images/drawing-header.png'

import { scrollToIdOnClick } from '../../utils/scrollToSection'

import * as S from './styles'

function HeaderWeb({ fnSetMenuPhone, menuOpened }) {
  const [menuExpanded, setMenuExpanded] = useState(menuOpened)

  const handleMenu = () => {
    setMenuExpanded(!menuExpanded)
    fnSetMenuPhone(!menuOpened)
  }

  return (
    <S.MenuWrapper>
      <S.MenuPhone menuExpanded={menuOpened} onClick={handleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </S.MenuPhone>

      <S.HeaderWrapper>
        <S.HeaderLeft>
          <S.HeaderLogo>
            <S.ImageLogo src={Image} alt="Logo Coruja" />
          </S.HeaderLogo>
          <S.HeaderTitle>
            As melhores coleções de livros com preços exclusivos
          </S.HeaderTitle>
          <S.HeaderDescription>
            Adquira as principais coleções de livros para Arquitetura, Direito,
            Fisioterapia e Educação Física. É rápido, simples e prático!
          </S.HeaderDescription>
          <S.ButtonContainer>
            <Button
              label="Veja as coleções"
              onClick={() => scrollToIdOnClick('collections-section')}
            />
          </S.ButtonContainer>
        </S.HeaderLeft>
        <S.HeaderRight>
          <S.HeaderNav>
            <S.HeaderNavBuyNow
              onClick={() => scrollToIdOnClick('contact-section')}
            >
              Adquira Já
            </S.HeaderNavBuyNow>
            <S.HeaderNavColections
              onClick={() => scrollToIdOnClick('collections-section')}
            >
              Coleções
            </S.HeaderNavColections>
          </S.HeaderNav>
          <Button
            label="Tenho interesse"
            onClick={() => scrollToIdOnClick('contact-section')}
          />
        </S.HeaderRight>
      </S.HeaderWrapper>
      <S.SpotContainer>
        <img src={Spot} alt="Efeito do header, mancha de tinta" />
        <S.PaintContainer>
          <img src={DrawingHeader} alt="Desenho duas pessoas estudando" />
        </S.PaintContainer>
      </S.SpotContainer>
    </S.MenuWrapper>
  )
}

HeaderWeb.propTypes = {
  fnSetMenuPhone: PropTypes.func.isRequired,
  menuOpened: PropTypes.bool.isRequired
}

export default HeaderWeb
