import React, { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import PropTypes from 'prop-types'
import Image from '../Image'

import * as S from './styles'

const ReviewCard = ({ name, photo, text }) => {
  useEffect(() => {
    const texts = document.querySelectorAll('p.description')

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        entry.target.classList[
          entry.target.scrollHeight > entry.contentRect.height + 25
            ? 'add'
            : 'remove'
        ]('truncated')
      }
    })

    texts.forEach((text) => observer.observe(text))
  })

  return (
    <S.Card>
      <S.Title>{name}</S.Title>
      <Image filename={photo} alt={text} />
      <S.BookDetails>
        <S.DescriptionBook>
          <S.Text>{text}</S.Text>
        </S.DescriptionBook>
      </S.BookDetails>
    </S.Card>
  )
}

ReviewCard.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default ReviewCard
