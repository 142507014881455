import styled from 'styled-components'
import { darken } from 'polished'

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #3ad686;

  width: 243px;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 1rem;

  transition: background 0.3s;

  &:hover {
    background: ${darken(0.1, '#3ad686')};
  }
`

export const ButtonLabel = styled.span`
  font-size: 16px;
  color: #ffff;
  text-transform: uppercase;
  font-weight: bold;
`
