import React from 'react'
import Divisor from '../../../static/assets/divisor.png'

import Step from './step'
import { steps } from './content'

import * as S from './styles'

function SectionSteps() {
  return (
    <S.StepsContainer>
      <S.TopDivisor
        src={Divisor}
        alt="Imagem abstrata apenas pada dividir seções do site"
      />
      <S.StepData>
        <S.StepTitle>Veja como é simples adquirir as coleções.</S.StepTitle>
        <S.StepContent>
          {steps.map(({ id, title, description, color, stepNumber }) => (
            <Step
              key={id}
              title={title}
              description={description}
              color={color}
              stepNumber={stepNumber}
            />
          ))}
        </S.StepContent>
      </S.StepData>

      <S.BottonDivisor
        src={Divisor}
        alt="Imagem abstrata apenas pada dividir seções do site"
      />
    </S.StepsContainer>
  )
}

export default SectionSteps
