export const administracaoPhotos = {
  id: 1,
  name: 'Coleção de Livros Administração',
  featuredPhoto: 'colecao-completa.jpg',
  text: `Essa coleção conta com livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Princípios da Administração',
      photo: 'principios-da-administracao.jpg',
      description: `
        A partir da análise aprofundada das principais escolas e abordagens administrativas, como comportamental, sistêmica, humanística e contingencial, a 2ª edição de Princípios do Administração oferece conceitos e modelos para ajudar o leitor a desenvolver a habilidade administrativa diante das mais incomuns e imprecisas situações vivenciadas no mercado.

        Destinado tanto a estudantes de administração como a todos os profissionais que necessitam de uma base conceituai e teórica indispensável à prática administrativa, esta obra apresenta uma visão crítica e objetiva, destacando alternativas inovadoras para o cada vez mais instável e turbulento mundo dos negócios
      `
    },
    {
      id: 2,
      name: 'Gestão Financeira',
      photo: 'gestao-financeira.jpg',
      description: `
        Gerar riqueza por meio de produtos, serviços, energia, informação, educação e entretenimento de maneira continuada e sustentável - esta é uma das finalidades de toda empresa ou empreendimento humano.
        Quase sempre, a riqueza produzida e acumulada por essas finalidades se traduz em termos financeiros, ou melhor, em ativos financeiros.
        E é isso que define o grau de sucesso da empresa.

        Para tanto, cada empresa precisa saber lidar com seus recursos e competências a fim de obter o máximo rendimento de sua aplicação.
        Conhecer os meandros e segredos da gestão financeira é uma necessidade imperativa para quem trabalha em ou com empresas.

        Proprietários, dirigentes, gerentes, executivos, clientes e usuários, fornecedores, consultores, empregados, acionistas, investidores e aplicadores precisam saber diagnosticar e avaliar o grau de sucesso de uma empresa e sua competência em lidar com ativos financeiros e apresentar resultados e retornos excelentes.

        Conhecer as implicações financeiras do negócio empresarial requer familiarização, no lado interno, com as demonstrações financeiras, a análise do fluxo de fundos e a gestão do capital de giro, e, no lado externo, com o mercado financeiro e suas oscilações.
        A preparação de uma empresa para a nova realidade do mundo dos negócios implica necessariamente uma renovação de seus princípios e filosofias e isso certamente diz respeito também à área financeira.

        Este livro foi elaborado com o objetivo de proporcionar uma visão genérica e integrada dos vários processos financeiros, em uma abordagem introdutória. Sua leitura é indicada a todos os envolvidos nessa desafiante área de atividade, sejam profissionais do ramo, estudantes, investidores, consultores externos ou internos.
      `
    },
    {
      id: 3,
      name: 'Iniciação à Administração de Recursos Humanos',
      photo: 'iniciacao-administracao-recursos-humanos.jpg',
      description: `
        Qual é a principal causa do sucesso empresarial?
        O que leva as empresas a alcançarem excelência em suas operações?
        São os seus produtos? Seus processos de produção?

        A qualidade e produtividade? Tudo isso são apenas conseqüências de uma origem maior.
        A principal força que leva as empresas ao sucesso são as pessoas que dela participam.
        Elas proporcionam a inteligência, a vida, o dinamismo, a razão e o conhecimento que se traduzem em decisões e ações eficazes. Saber escolher as pessoas, como capacitá-las, orientá-las, impulsioná-las, dar-lhes condições de participação e engajamento é a base fundamental do êxito empresarial.

        Saber transformar as pessoas em verdadeiros talentos constitui o primeiro passo.
        O segundo é saber transformar esses talentos em capital humano capaz de produzir retornos fabulosos.
        A administração de recursos humanos é hoje o principal diferencial competitivo que leva as empresas a patamares invejáveis de sustentabilidade e sucesso
      `
    },
    {
      id: 4,
      name: 'Direito do Trabalho para Administradores',
      photo: 'direito-do-trabalho-para-administradores.jpg',
      description: `
        O autor reuniu sua experiência como professor à frente do curso de Administração de Empresas da Universidade Presbiteriana Mackenzie para oferecer ao estudante e ao profissional da área subsídios fundamentais ao estudo e à aplicação das leis trabalhistas.
        Os temas foram divididos em 12 unidades, que abordam, entre outros:

        • A evolução histórica do Direito do Trabalho e seus principais conceitos;
        • A interpretação das leis;
        • A Consolidação das Leis do Trabalho (CLT);
        • O contrato individual de trabalho;
        • A proteção ao trabalho da mulher e do menor;
        • As normas de garantia de um desempenho saudável do trabalho;
        • O estabelecimento e as implicações cio Fundo de Garantia do Tempo de Serviço (FGTS);
        • A terceirização;
        • O surgimento da Justiça do Trabalho;
        • As normas regulamentadoras relativas à segurança e à medicina do trabalho.
      `
    },
    {
      id: 5,
      name: 'Como Administrar a Produção',
      photo: 'como-administrar-producao.jpg',
      description: `
        A tendência crescente de inclusão de atividades complementares ao currículo dos cursos de graduação e o uso da internet como ferramenta para disseminar o conhecimento foram os grandes motivadores para a elaboração da série Lições de Gestão, da qual este livro é o terceiro volume.

        O conteúdo do livro somado ao conjunto de materiais complementares disponíveis na plataforma da editora (www. manoleeducacao.com.br), formado por tutoriais, materiais interativos e videoaulas, é um verdadeiro curso a distância sobre administração da produção.
        0 leitor poderá fazer os exercícios propostos e responder aos questionários on-line para receber um certificado; no caso de estudantes, será possível registrar esse certificado junto à secretaria da instituição de ensino superior (IES) como atividade complementar.

        Escrito de forma simples, objetiva e pedagógica, este livro está estruturado em forma de aulas, a fim de inserir o leitor no contexto da obra.
      `
    },
    {
      id: 6,
      name: 'Como Calcular o Preço da Venda',
      photo: 'como-calcular-preco-de-venda.jpg',
      description: `
        A tendência crescente de inclusão de atividades complementares ao currículo dos cursos de graduação e o uso da internet como ferramenta para disseminar o conhecimento foram os grandes motivadores para a elaboração da série Lições de Gestão, da qual este livro é o marco inicial.
        Este primeiro volume, escrito de forma simples, objetiva e didática, corresponde a um curso a distância. Estruturado em aulas, oferece, além do material impresso, conteúdo on-Line no website da editora (www.manoleeducacao.com.br), com tutoriais, materiais interativos e videocasts, elaborados para guiar o aluno na trilha da aprendizagem.
        Como calcular o preço de venda foi escolhido para iniciar a série em função da importância do tema da precificação dos produtos e serviços para estudantes, profissionais da área administrativa e empresários interessados no assunto.
      `
    },
    {
      id: 7,
      name: 'Como Elaborar o Plano de Negócios',
      photo: 'elaborar-plano-negocio.jpg',
      description: `
        A tendência crescente de inclusão de atividades complementares ao currículo dos cursos de graduação e o uso da internet como ferramenta para disseminar o conhecimento foram os grandes motivadores para a elaboração da série Lições de Gestão, da qual este livro é o segundo volume.

        Escrito de forma simples, objetiva e pedagógica, corresponde a um curso a distância. Estruturado em aulas, oferece, além do material impresso, conteúdo on-line na plataforma da editora (www.manoleeducacao.com.br), com tutoriais, materiais interativos e videoaulas, elaborados para guiar o aluno na trilha da aprendizagem.

        A escolha do tema "como elaborar o plano de negócios" deve-se à importância que esse instrumento representa para qualquer tipo de empresa e à dificuldade que ela enfrenta para utilizá-lo no momento de pensar o futuro.
      `
    },
    {
      id: 8,
      name: 'Coleção Gestão Total',
      photo: 'gestao-total.jpg',
      description: `
        Este material contém 5 livros:

        - Carreira
        - Liderança
        - Empreendedorismo
        - Vendas
        - Sucesso
      `
    },
    {
      id: 9,
      name: 'Coleção Turbine Seu Negócio',
      photo: 'turbine-seu-negocio.jpg',
      description: `
        4 Videoaulas e 4 Livros
        Com autores e seus temas:

        Autor César Frazão - Turbine suas Vendas
        Autor Conrado Navarro - Turbine suas Finanças
        Autor Eduardo Carmello - Turbine sua Equipe
        Autor Roberto Shinyashiki - Turbine sua Estratégia Profissional
      `
    },
    {
      id: 10,
      name: 'Coleção Sucesso! Pense Nisso!',
      photo: 'sucesso-pense-nisso.jpg',
      description: `
        As célebres palestras motivacionais do Prof. Marins agora estão transcritas na coleção Sucesso! Pense Nisso! São três volumes que deixarão você preparado para enfrentar os desafios do mundo contemporâneo, acompanhado de 2 DVD'S e 1 CD.

        Pense nisso! Sucesso!
      `
    },
    {
      id: 11,
      name: 'A Bíblia do Empreendedor',
      photo: 'biblia-do-empreendedor.jpg',
      description: `
        Você, que está ávido por mudança, quer finalmente transformar uma ideia em uma empresa, está cansado da vida dentro de escritórios ou mesmo desempregado: não importa os motivos, mas a finalidade!

        Siga seu sonho e transforme sua paixão em profissão! Com A bíblia do empreendedor, você aprenderá a planejar, coordenar e desenvolver uma empresa de sucesso, que obtenha retorno rápido e permaneça em um mercado tão competitivo quanto o nosso.

        Este livro oferece uma linha do tempo prática, com planejamentos mensais e os passos que você precisa dar para se tornar um grande empreendedor em um ano.

        Conheça pessoas que vão ajudá-lo, faça uma projeção de marketing, crie o logotipo e o nome perfeitos, adquira ferramentas e padrões ideais, enfim, com a ideia e a energia disponíveis você será o empreendedor que sempre quis ser.

        Não perca tempo, comece seu projeto hoje mesmo!
      `
    },
    {
      id: 12,
      name: 'Empreender em 100 Lições',
      photo: 'empreender-em-100-licoes.jpg',
      description: `
        Você tem um negócio? Planeja começar um?

        Nâo importa se experiente ou iniciante, se você é um empreendedor, certamente deseja saber tudo o que é possível para ser bem-sucedido.
        E se você pudesse conhecer os sábios conselhos e as valiosas dicas que os mestres do empreendedorismo de todos os tempos têm a oferecer?

        É exatamente isso o que este livro traz: dicas e informações de renomados empreendedores, resultado de seu conhecimento e experiência, sobre assuntos como oportunidades, motivação, novas perspectivas, desafios, dinheiro, estratégia, marketing, vendas e sucesso.
        Textos sucintos e inspiradores traduzem-se em 100 lições que abrangem os principais aspectos da gestão, e que o ajudarão a saber mais e a ter ideias e respostas para que você consiga, com brilho e sucesso, alcançar todos os objetivos que almeja.
      `
    },
    {
      id: 13,
      name: 'Trabalhar por Conta Própria',
      photo: 'trabalhar-por-conta-propria.jpg',
      description: `
        Trabalhar por conta própria - Como preparar-se para empreender o seu voo solo é um roteiro prático para quem quer se tornar empreendedor.

        O autor, José Augusto Minarelli, conselheiro de carreiras e especialista em outplacement®,acompanha há cerca de trinta anos o processo de tomada de decisão de executivos que resolvem iniciar um negócio próprio depois de perder o emprego convencional.

        Com base nessa experiência, Minarelli elaborou um manual prático com check-ups de carreira e exercícios de autoanálise que vão testar seus conhecimentos, suas habilidades e sua disposição para o empreendedorismo.

        Trabalhar por conta própria ainda traz dicas para o sucesso do trabalho autoempresariado e vai ajudá-lo a descobrir, com mais segurança, se você está pronto para abrir seu próprio negócio.
      `
    }
  ]
}
