import styled from 'styled-components'
import media from 'styled-media-query'
import { darken, lighten } from 'polished'

export const ModalProductDetails = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 400ms ease-in;
`

export const CloseModal = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  background: ${lighten(0.1, '#303049')};
  border-radius: 50%;

  cursor: pointer;
  z-index: 10;

  > svg {
    color: #fff;
  }

  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }

  ${media.lessThan('medium')`
    width: 30px;
    height: 30px;
    top: 0px;
  right: 0px;
  `}
`

export const ModalContainer = styled.div`
  display: flex;
  background: #fff;
  height: 70%;
  width: 90%;
  z-index: 8;
  opacity: 1;
  position: relative;
  border-radius: 5px;
  max-width: 1280px;

  ${media.lessThan('medium')`
    flex-direction: column;
    height: 90%;
    width: 90%;
  `}
`

export const ProductFixedTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #303049;
  width: 30%;

  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  ${media.lessThan('medium')`
    height: 20%;
    width: 100%;
    border-radius: 5px 5px 0 0 ;
  `}
`

export const ProductFixedTitle = styled.h1`
  color: #fff;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}
`

export const ProductFixedDescription = styled.p`
  color: #fff;
  line-height: 1.7rem;
  text-align: center;
  font-size: 1.4rem;

  ${media.lessThan('medium')`
    line-height: 1.1rem;
    text-align: center;
    font-size: .9rem;
  `}
`

export const ButtonImInterested = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #3ad686;

  width: 100%;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;

  transition: background 0.3s;

  &:hover {
    background: ${darken(0.1, '#3ad686')};
  }

  font-size: 16px;
  color: #ffff;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0.4rem;

  ${media.lessThan('medium')`
    min-height: 30px;
  `}
`

export const Drawing = styled.img`
  width: 200px;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const ContainerCarousel = styled.div`
  display: flex;
  width: 70%;
  height: 100%;

  ${media.lessThan('medium')`
    width: 100%;
    height: 80%;
  `}
`

export const Content = styled.section`
  width: 100%;
  height: 100%;

  div.slick-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .slick-list {
    position: relative;
    height: 100%;
    display: block;

    div {
      height: 100%;
    }
  }

  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slider {
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .slick-list:focus {
    outline: 0;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    top: 0;
    left: 0;
  }

  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-list {
  }

  .slick-dots {
    list-style: none;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;

    li {
      background: #a8a8ad;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.8rem;
      cursor: pointer;

      ${media.lessThan('1045px')`
        margin: 0 0.3rem;
      `}

      &.slick-active {
        background: #26b76d;
      }
    }

    button {
      opacity: 0;
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
`
