export const steps = [
  {
    id: 1,
    stepNumber: '1',
    title: 'Preencha o formulário',
    description: `Envie suas informações e escolha a
    coleção de interesse.`,
    color: '#434068'
  },
  {
    id: 2,
    stepNumber: '2',
    title: 'Entraremos em contato',
    description: `Nosso time de vendas entrará em
    contato com você para lhe oferecer
    as melhores condições de pagamento.`,
    color: '#FF6484'
  },
  {
    id: 3,
    stepNumber: '3',
    title: 'Bons estudos',
    description: `Receba de forma prática e rápida
    a sua coleção de livros para estudo.`,
    color: '#3AD686'
  }
]
