export const edFisicaBachaPhotos = {
  id: 1,
  name: 'Ed. Física Bacharel',
  featuredPhoto: 'colecaoCompletaEdFisBach.jpg',
  text: `Essa coleção conta com 13 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Nutrição e Suplementação Esportiva',
      photo: 'nutricaoSuplementacao.jpg',
      description: `
        A presente obra procura destacar a importância da nutrição e da reposição de nutrientes para a prática esportiva, bem como sua relação com o desempenho esportivo e os benefícios à saúde.

        O livro aborda aspectos gerais sobre:
        - nutrição esportiva;
        - macro e micronutrientes;
        - importância da nutrição e da suplementação nos exercícios e no emagrecimento;
        - fitoterapia aplicada à nutrição esportiva;
        - nutrigenômica e nutrigenética associadas ao desempenho esportivo;
        - doping e doping genético.

        Trata-se de uma obra dirigida a todos os profissionais que trabalham com atividades associadas ao esporte e que têm particular interesse nos efeitos da nutrição sobre o organismo, bem como aos vários profissionais da saúde, tendo em vista o importante papel desempenhado pela nutrição para um envelhecimento saudável e para o tratamento de vários distúrbios que atingem o sistema nervoso periférico e central.
      `
    },
    {
      id: 2,
      name: 'Personal Trainer',
      photo: 'personalTrainer.jpg',
      description: `
        O livro ensina como trabalhar o core utilizando a sala de musculação, valendo-se de vários métodos para que se consiga melhorar, intensificar e modificar os estímulos musculares. Tudo isso direcionando para o personal trainer, que poderá entender com detalhes como montar um estúdio e, assim, começar seu próprio negócio.

        Luis Cláudio Bossi
      `
    },
    {
      id: 3,
      name: 'Desvendando o Emagrecimento',
      photo: 'desvendandoOEmagrecimento.jpg',
      description: `
        A motivação para escrever este livro de perguntas e respostas sobre o emagrecimento nasceu, sobretudo, de nossa experiência prática adquirida por meio do contato com alunos e pacientes.

        No decorrer de nossa vida acadêmica, docente e clínica dos últimos 15 anos, observamos que muitas dúvidas são recorrentes. Assim, selecionamos as 101 perguntas que julgamos mais pertinentes sobre exercício, nutrição e emagrecimento e, nos capítulos deste livro, tentaremos trazer à tona as respostas de forma clara e aplicável para todos os públicos, desde praticantes até professores de Educação Física e nutricionistas, sem abrir mão do embasamento científico.
      `
    },
    {
      id: 4,
      name: 'Marketing Pessoal do Personal Trainer',
      photo: 'marketingPessoalDoPersonal.jpg',
      description: `
      Marketing pessoal do personal trainer: estratégias práticas para p sucesso é uma ferramenta facilitadora para o sucesso profissional, oferecendo aos seus leitores uma visão do personal trainer como empresa, explorando diversos aspectos complementares à formação profissional, com ênfase no marketing pessoal. Em suma, a obra é o divisor de águas entre “um profissional” e “o profissional”.
      `
    },
    {
      id: 5,
      name: 'Musculação Funcional',
      photo: 'musculacaoFuncional.jpg',
      description: `
        Por meio de uma linguagem agradável e envolvente, além de muitas ilustrações dos exercícios propostos, Musculação funcional: ampliando os limites da prescrição tradicional oferece o aporte e prático para aplicação do treinamento funcional na sala de musculação. Esta 2ª edição traz ao leitor importantes atualizações, com o resultado de diversas pesquisas na área, contribuindo para a realização de um programa de exercícios cada vez mais eficiente e eficaz, seja para os que buscam alto rendimento ou para aqueles interessados na melhora da saúde da qualidade de vida.
      `
    },
    {
      id: 6,
      name: 'Exercícios de Hidroginástica',
      photo: 'exerciciosHIdroginastica.jpg',
      description: `
        Divirta-se, entre em forma e mantenha-se saudável com Exercícios de Hidroginástica. Com mais de 130 exercícios que fazem uso da resistência natural da água, você melhorará a composição e a tonificação do corpo, fortalecerá os músculos, aumentará a resistência aeróbica e muscular desenvolvendo flexibilidade, coordenação e agilidade – com mínimo estresse sobre o corpo.

        Além de exercícios individuais criativos, Exercícios de Hidroginástica inclui 14 programas passo a passo que podem ser adaptados às suas necessidades, interesses e metas para o condicionamento físico. É possível, inclusive, incrementar e diversificar, experimentando sessões com tai chi, pilates, ioga, kickboxing, dança country, dança de rua e exercícios com rolos de espuma.

        Todas as informações essenciais constam nesta obra, qualquer que seja o seu objetivo: programas gerais para o condicionamento físico completo, cross-training e melhora no desempenho físico completo, cross-training e melhora no desempenho, assim como rotinas mais específicas para gravidez, reabilitação física, recuperação cardíaca ou planos para adultos.
      `
    },
    {
      id: 7,
      name: 'Gestão em Atendimento',
      photo: 'gestaoEmAtendimento.jpg',
      description: `
        Um dos objetivos deste livro é “quebrar” o entendimento de que atendimento é somente um conjunto de atitudes. Atendimento é mais que um conjunto de belas palavras. E ser um bom atendente é mais do que ser um ótimo ouvinte. É mais do que ser educado e vestir-se bem. É mais que ser proativo. A verdadeira qualidade de atendimento de uma empresa está diretamente relacionada à cultura que é desenvolvida dentro dela; ao que cada pessoa traz das suas experiências profissionais e pessoais anteriores; e à combinação de todas essas experiências no próprio ambiente de trabalho onde todos os envolvidos estão comprometidos em melhor atender os clientes.

        Qualidade de atendimento é um sistema que combina infraestrutura inteligente, processos práticos e objetivos e pessoas educadas, capacitadas e comprometidas. Tudo isso para levar aos clientes soluções, prontidão e simpatia num clima acolhedor.
      `
    },
    {
      id: 8,
      name: 'Guia para Avaliações do Condicionamento Físico',
      photo: 'guiaDeAvaliacaoDeCondicionamento.jpg',
      description: `
        Esta obra reúne as informações mais importantes obtidas das pesquisas conduzidas por renomados cientistas e profissionais especializados em avaliar e mensurar os componentes básicos do condicionamento físico. Desenvolvido pela National Strength and Conditioning Association (NSCA), organização líder mundial no assunto, o livro traz dados relevantes sobre testes de desempenho e análise de exames clínicos, abrangendo tópicos como metabolismo e taxa metabólica, força e resistência, velocidade, agilidade, mobilidade, equilíbrio e estabilidade.

        O leitor encontrará ainda informações sobre as aplicações profissionais de todos os testes, desde aqueles mais simples (como composição corporal e pressão arterial) aos mais complexos e específicos (como teste de limiar de lactato e potência aeróbia), capacitando-se para desenvolver e implantar seus próprios programas de testes e avaliações.

        Este guia contém:
        - Fundamentos da criação e realização de testes, da análise de dados, dos métodos de interpretação de resultados e da elaboração de conclusões;
        - Orientações sobre a implementação de dados coletados com foco na elaboração de programas de treinamento seguro e eficientes;
        - Avaliações com instruções passo a passo e protocolos aprovados pela NSCA;
        - Tabelas e gráficos que facilitam a compreensão do conteúdo;
        - Extensa bibliografia que oferece pontos de partida para um estudo mais detalhado do tema.
      `
    },
    {
      id: 9,
      name: 'Nutrição para o Treinamento de Força',
      photo: 'nutricaoParaTrinamento.jpg',
      description: `
        Já em sua 4ª edição, revista e atualizada, esta obra apresenta ao leitor o plano Power Eating, um método prático e comprovado para ganho de massa muscular, eliminação de gordura e aumento do desempenho, recomendado a praticantes de treinamento de força de todos os níveis.

        A autora Susan Kleiner, PhD em nutrição que acompanhou atletas e fisiculturistas de destaque internacional, revela neste livro as melhores estratégias para alcançar essas metas de uma forma totalmente segura, saudável, legal e eficaz. Com uma linguagem objetiva e uma abordagem de fácil aplicação, Nutrição para o treinamento de força traz:
        - as bases científicas para entender a função os carboidratos, gorduras e proteínas e como consumi-los de forma que trabalhem a favor de seus objetivos;
        - planos dietéticos personalizados para homens e mulheres, com sugestões de como compor cada uma das refeições;
        - novos achados referentes ao uso de ervas e plantas para melhora do desempenho;
        - um guia de suplementos baseado nos estudos mais recentes, que mostra como selecionar as melhores opções com base em sua pureza, eficácia, digestibilidade e absorção.

        Incorpore o plano Power Eating em sua rotina e descubra o que milhares de atletas auxiliados pela Dra. Kleiner já sabem. Nutrição para o treinamento de força é mais que um livro. É sua trilha para o caminho da excelência.
      `
    },
    {
      id: 10,
      name: 'Fisiologia do Exercício',
      photo: 'fisiologiaDoExercicio.jpg',
      description: `
        • Conta com a participação de pesquisadores de renomadas universidades brasileiras, detentores dos mais elevados gabaritos e de grande experiência nas suas respectivas áreas de atuação.
        • Proporciona um texto amplo e transdisciplinar sobre os principais tópicos relacionados á Fisiologia do Exercício para a manutenção do estado de saúde e da qualidade de vida.
        • Aborda os efeitos do exercício físico nos diversos sistemas fisiológicos, além das particularidades de suas respostas e adaptações em populações e condições ambientais especiais.
        • Discute aspectos relativos ao condicionamento físico, englobando um extenso conteúdo dedicado á avaliação e ao treinamento físico dos atributos associados á aptidão física.
        • Destina-se a estudantes, professores e profissionais das áreas de Ciências da Saúde e Ciências da Atividade Física.
      `
    },
    {
      id: 11,
      name: 'Teste de Aptidão Física para Idosos',
      photo: 'testeDeAptidaoFisica.jpg',
      description: `
        Teste de Aptidão Física para Idosos oferece um método simples com testes de fácil uso na avaliação da aptidão funcional de idosos. Os testes são seguros e bem aceitos para a faixa etária a que se destinam, seguem padrões científicos confiáveis e legítimos e incluem resultados reais obtidos por meio de testes com mais de 7.000 idosos.

        A obra, dedicada a profissionais e estudantes de fisioterapia e geriatria, inclui:
        - A base teórica e os procedimentos sobre os quais os testes foram elaborados.
        - Informações completas sobre a aplicação e a pontuação dos testes com grupos de idosos, além de fornecer feedback a eles.

        Além de ajudar os profissionais a motivarem os idosos à prática de exercício físico, Teste de
        Aptidão Física para Idosos também é um importante instrumento de avaliação da capacidade funcional para detectar debilitações iminentes e trata-las antes que causem sérias limitações físicas.
      `
    },
    {
      id: 12,
      name: 'Mobilização e Alongamento na Função Musculoarticular',
      photo: 'mobilizacaoEAlongamento.jpg',
      description: `
        Mobilização e alongamento na função musculoarticular apresenta técnicas para se utilizar principalmente em pessoas com restrição na amplitude músculoarticular.

        O fato de empregarmos mobilização articular associada com alongamento não significa que a primeira envolva somente a articulação e que o segundo tenha ação exclusivamente muscular, mas que, dependendo do compromentimento, um desses componentes possa estar mais restritivo do que outro. Dessa forma, o foco constitui-se no entendimento integrado em como os componentes do tecido conjuntivo relacionam-se estruturalmente e comportam-se em uma determinada função.

        O movimento articular influencia tanto os músculos de determinada articulação como a normalização do excesso de tônus ou a supressão do encurtamento tem influência na articulação. A fisiologia articular é estreitamente inter-relacionada com a estrutura e a função articulares.

        A mobilização articular com ênfase na artrocinemática, o escopo central deste livro, compreende movimentos da superfície articular que agem sobre a cápsula articular, espaço intra-articular, ligamento e músculo. E, com esse feito, ou com outros tais como inibição de dor e relaxamento, poderá facilitar a realização dos exercícios de alongamento feito posteriormente, restaurando a amplitude de movimento.

        Para realizar uma boa técnica de mobilização e alongamento, é preciso muita concentração para que os conteúdos sejam aplicados de forma rítmica, sutil e determinada; tendo sempre em mente que o respeito ao corpo do cliente é fundamental.

        A leitura de Mobilização e alongamento na função musculoarticular de forma integral é de grande importância para se trabalhar as técnicas para desenvolvimento da flexibilidade, pois o livro apresenta uma série de imagens e exemplos práticos que facilitam a compreensão do conteúdo e auxiliam o profissional em sua prática diária.
      `
    },
    {
      id: 13,
      name: 'Anatomia de Treinamento de Força',
      photo: 'anatomiaDoTreinamentoDeForca.jpg',
      description: `
        Nesta obra, o renomado treinador de força Bret Contreras ensinar como aumentar a força, adquirir massa, queimar gordura e definir os músculos por meio de uma modalidade de treino que utiliza o próprio peso do corpo como carga, o que proporciona ao praticante enorme versatilidade e altos ganhos de uma forma simples e altamente eficaz.

        Abrangendo as regiões dos principais músculos do corpo – braços, tórax, ombros, dorso, core, coxas e glúteos -, Anatomia do treinamento de força apresenta 156 dos mais efetivos exercícios que podem ser executados em qualquer lugar e a qualquer momento, sem que seja preciso utilizar pesos livres, aparelhos ou equipamentos caros. Por meio de conselhos valiosos, o leitor aprenderá ainda como modificar, combinar e ordenar os exercícios a fim de evoluir sua rotina de treino e evitar o efeito platô.

        Com o auxílio de ilustrações anatômicas detalhadas e de instruções passo a passo, este livro promove um treino consciente e seguro, revelando o que ocorre no corpo durante o exercício e destacando os principais músculos trabalhados, além de estruturas adjacentes, como ossos, ligamentos e tendões.

        Se você está apenas começando sua busca por um corpo mais forte e definindo ou se simplesmente procura uma abordagem eficaz e comprovada para treinar em casa, durante viagens ou em qualquer lugar em que estiver, Anatomia do treinamento de força é uma referência única e indispensável, que o levará sempre adiante em seu aperfeiçoamento.
      `
    }
  ]
}
