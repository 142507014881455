export const livrosEngenharia = {
  id: 1,
  name: 'Livros Engenharia',
  featuredPhoto: 'colecaoCompletaLivEng.jpg',
  text: `Essa coleção conta com 07 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Fundamentos da Engenharia de Ediificações',
      photo: 'fundamentosDaEngenharia.jpg',
      description: `
        O PRINCIPAL LIVRO SOBRE CONSTRUÇÃO DE EDIFICAÇÕES

        Este livro é utilizado por milhares de profissionais e estudantes de engenharia, arquitetura e tecnologia de construção desde sua primeira edição, há quase um quarto de século! Seu sucesso se deve á organização lógica do texto, ás esclarecedoras ilustrações (incomuns aos livros da área), á redação clara e ao leiaute agradável. Além disso, tem características únicas, pois é um texto integrativo (as técnicas de edificação são apresentadas como sistemas integrados), seletivo (traz todo o conteúdo básico, que deve ser expandido posteriormente), capacitador (estruturado em torno do processo de projetar e construir edificações) e complexo, sem ser complicado para o leitor.

        Este livro cobre o espectro completo de atividades de construção de edificações – das escavações e fundações aos revestimentos e acabamentos internos – e examina sistemas construtivos comuns, como molduras estruturais em madeira e aço, alvenaria estrutural e construções em concreto armado.

        Traz um amplo conjunto de informações sobre tópicos atuais, como:

        • O novo papel do empreiteiro
        • Gestão da construção e métodos de entrega de projetos
        • Controle dos fluxos de calor, ar e umidade através da envoltória das edificações
        • Sustentabilidade e técnicas construtivas verdes
        • Inovações em vidros, concreto, madeira e outros materiais de construção

        Fundamentos da Engenharia de Edificações estabelece a base necessária para uma carreira de sucesso a todo engenheiro, arquiteto e profissional da construção.
      `
    },
    {
      id: 2,
      name: 'Estática e Mecânica dos Materiais',
      photo: 'estaticaEMecanicaDosMateriais.jpg',
      description: `
        Mantendo a metodologia de ensino tradicional dos seus famosos livros-texto, Beer e Johnston unem nesta obra conceitos e aplicações de duas importantes áreas da engenharia – a estática e a mecânica dos materiais – permitindo que os estudantes desenvolvam a habilidade de compreender e solucionar um determinado problema de maneira coesa, simples e lógica.

        • Os capítulos têm início com exemplos reais e com um sumário resumido dos conteúdos que serão trabalhados.
        • Os conceitos são introduzidos passo a passo, de forma clara e objetiva.
        • Seções opcionais oferecem tópicos avançados.
        • As seções Problemas resolvidos são apresentadas em uma única página, o que proporciona melhor visualização dos problemas-chave.
        • Todos os capítulos oferecem um conjunto de problemas que devem ser resolvidos com o auxílio de programas computacionais.
      `
    },
    {
      id: 3,
      name: 'Hidráulica para Engenharia Civil e Ambiental',
      photo: 'hidraulica.jpg',
      description: `
        Á medida que as pesquisas na área de hidráulica continuam a procurar soluções para muitos problemas urgentes na gestão hídrica e ambiental, engenheiros e cientistas se deparam com desafios estimulantes e difíceis que exigem o máximo de sua capacidade técnica.

        Hidráulica para engenharia civil e ambiental reúne uma abordagem completa dos princípios básicos da hidráulica em engenharia civil e uma ampla cobertura de aplicações práticas no mundo real.

        Este texto clássico foi cuidadosamente estruturado em duas partes para apresentar os princípios básicos antes de passar para tópicos mais avançados.

        A primeira parte se concentra nos fundamentos que incluem hidrostática, hidrodinâmica, fluxo em tubulações e em canais abertos, teoria das ondas, modelagem física, hidrologia e transporte de sedimentos.

        A segunda parte ilustra as aplicações desses princípios fundamentais em engenharia, no projeto de sistemas de tubulação, estruturas hidráulicas e engenharia de rios, canais e costeira – incluindo implicações ambientais atualizadas. O capítulo dedicado á hidráulica computacional demonstra, em vários contextos, a aplicação de técnicas de simulação computacional em projetos modernos.

        Nesta 5ª edição foram feitas minuciosas revisões dos capítulos sobre máquinas hidráulicas, hidrologia das enchentes e modelagem computacional. Novos assuntos foram adicionados aos capítulos que tratam de hidrostática, princípios do fluxo de fluidos, comportamento de fluidos reais, fluxo em canais abertos, surto de pressão em tubulações, teoria das ondas, transporte de sedimentos, engenharia fluvial e engenharia costeira. Esta edição também contém as últimas recomendações com relação ás previsões, impactos e medidas de adaptação referentes ás mudanças climáticas.

        Hidráulica para engenharia civil e ambiental, 5ª Edição é uma fonte essencial para alunos e profissionais de engenharia civil, engenharia ambiental, saúde pública e disciplinas afins. É uma obra abrangente,
      `
    },
    {
      id: 4,
      name: 'Propriedades do Concreto',
      photo: 'propriedadesDoConcreto.jpg',
      description: `
        Publicado pela primeira vez em 1963, Propriedades do Concreto se estabeleceu como a referência definitiva sobre o assunto para os estudantes e profissionais de engenharia civil. Foi traduzido para mais de 12 idiomas e vendeu mais de meio milhão de exemplares.

        Desde então, passou por várias atualizações, chegando a esta edição para refletir todos os avanços da tecnologia do concreto na última década. O propósito é o mesmo:
        fornecer informações confiáveis, abrangentes e práticas sobre as propriedades e o uso do concreto, com uma seleção de proporções de mistura baseadas em estudos científicos e na extensa experiência do autor em engenharia civil. Neville ajuda o leitor a entender o comportamento do concreto e a relacioná-lo aos fenômenos químicos e físicos envolvidos em seu uso.
      `
    },
    {
      id: 5,
      name: 'Fundamentos de Topografia',
      photo: 'fundamentosDeTopografia.jpg',
      description: `
        Base de qualquer projeto ou obra realizada por engenheiros ou arquitetos, o levantamento topográfico é uma prática indispensável para determinar todos os detalhes de um terreno.
        Para ajudá-lo a compreender a importância da ciência topográfica e a entender como fazer um levantamento topográfico (métodos e tecnologias), os experientes professores
        Marcelo Tuler e Sérgio Saraiva apresentam neste livro os seguintes tópicos:

        • Resumo histórico da ciência topográfica
        • Fundamentos básicos de geodésia e topografia, suas aplicações e profissionais habilitados para sua prática
        • Processos, equipamentos e requisitos para um levantamento topográfico planimétrico
        • Métodos de levantamento altimétrico, com ênfase no nivelamento geométrico
        • Planialtimetria: exemplos do levantamento e do uso da planta planialtimétrica
        • Aspectos básicos das concordâncias horizontal e vertical, e da estatística aplicada á topografia
        • Biologia dos animais peçonhentos e prevenção de acidentes durante a atividade de campo

        Este lançamento da série Tekne é um instrumento pedagógico indispensável para alunos e professores dos cursos do eixo infraestrutura, previstos pelo Ministério da Educação, no Programa Nacional de Acesso ao Ensino Técnico e Emprego (Pronatec).
      `
    },
    {
      id: 6,
      name: 'Construção Civil na Prática',
      photo: 'canteiroDeObras.jpg',
      description: `
        Esta obra é fruto da pesquisa das dificuldades pelas quais passa o profissional de construção civil, quando da implantação dos serviços preliminares para um canteiro de obras.

        Neste contexto, a definição de um canteiro sempre é desafiadora, em face dos imprevistos, sejam em relação ao reconhecimento dos locais, á mobilização de recursos ou de serviços específicos, principalmente quando da existência de curtos prazos e restrições das condições.

        Assim, neste primeiro volume da coleção – Construção civil na prática – são descritas alternativas e soluções a serem realizadas na consolidação do canteiro de obras.
      `
    },
    {
      id: 7,
      name: 'Planejamento, Equipamentos e Métodos para a Construção Civil',
      photo: 'planejamentoEquipamentos.jpg',
      description: `
        Os projetos na construção civil exigem prazos cada vez menores. Nesse sentido, o planejamento ganha grande importância e deve incluir análises de utilização de equipamentos e planos de contingência para eventuais obstáculos. Também são necessárias a realização de atividades simultâneas e a abertura de múltiplas frentes de serviço, o que torna o conhecimento sobre a produtividade de equipamentos fundamental.

        Planejamento, equipamentos e métodos para a construção civil fornece ao leitor uma compreensão das capacidades das máquinas e de como aplicar corretamente esses recursos para vencer os desafios de construção. Esta edição contou com a ajuda de profissionais da área da construção civil de diversos lugares do mundo, dando continuidade aos esforços iniciados por Robert Peurifoy, há 55 anos, de escrever um livro baseado em conceitos extraídos da prática.

        DESTAQUES

        • Ênfase no manejo e na segurança de guindastes
        • Informações atualizadas sobre equipamentos de construção, coletadas a partir de visitas a fabricantes e locais de projeto em mais de 25 países
        • Metodologia de resolução de problemas passo a passo baseada na prática, o que resulta em aprendizagem mais focada

        Este livro é um valioso guia para estudantes de graduação dos cursos de engenharia civil e também para profissionais que atuam no setor de obras de infraestrutura, consultores, projetistas, técnicos em planejamento e profissionais de campo.
      `
    }
  ]
}
