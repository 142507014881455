import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import ReviewCard from '../ReviewCard'

import { photos } from './content'
import * as S from './styles'

const settings = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  infinite: false,
  speed: 500,
  rows: 1,
  slidesPerRow: 1,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        rows: 1,
        slidesPerRow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export default function SectionCollections({ fnSetModalDetailProduct }) {
  return (
    <S.SectionCollection>
      <S.Container id="collections-section">
        <S.Content>
          <S.Title>
            As edições mais completas para enriquecer a sua formação
          </S.Title>
          <Slider {...settings}>
            {photos.map((photoItem, idx) => (
              <ReviewCard
                key={idx}
                photoItem={photoItem}
                fnSetModalDetailProduct={fnSetModalDetailProduct}
              />
            ))}
          </Slider>
        </S.Content>
      </S.Container>
    </S.SectionCollection>
  )
}

SectionCollections.propTypes = {
  fnSetModalDetailProduct: PropTypes.func.isRequired
}
