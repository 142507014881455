import styled from 'styled-components'
import media from 'styled-media-query'
import { darken } from 'polished'

export const FooterWrapper = styled.footer`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const FooterContainer = styled.div`
  max-width: 1480px;
  padding: 0 1rem;
  width: 100%;
  margin-top: 2rem;
`

export const FooterActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  ${media.lessThan('large')`
    flex-wrap: wrap;
  `}
`

export const FooterBottonWhatsApp = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;

  margin-right: 1rem;
  border-radius: 5px;

  background: #3ad686;
  color: #fff;

  font-size: 1.2rem;
  font-weight: bold;

  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${darken(0.1, '#3ad686')};
  }

  ${media.lessThan('large')`
    font-size: 0.9rem;
    margin-right: 0;
    margin-bottom: .7rem;
    width: 100%;
  `}
`

export const FooterBottonBuyNow = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;

  border-radius: 5px;

  background: #434068;
  color: #fff;

  font-size: 1.2rem;
  font-weight: bold;

  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${darken(0.1, '#434068')};
  }

  ${media.lessThan('large')`
    font-size: 0.9rem;
    width: 100%;
  `}
`

export const FooterCopywight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.2rem;
`

export const FooterSiteBrand = styled.span`
  color: rgb(67, 64, 104);
  font-weight: bold;
  text-align: center;
`

export const FooterSiteDev = styled.span`
  margin: 0.7rem 0;
  font-weight: bold;
  color: #d3cfcf;
  text-align: center;

  > a {
    color: #d3cfcf;
    text-decoration: underline;
  }
`
