import { arquiteturaPhotos } from './photoDetails/arquitetura'
import { direitoPhotos } from './photoDetails/direito'
import { edFisicaBachaPhotos } from './photoDetails/edFisicaBacharel'
import { edFisicaLicentPhotos } from './photoDetails/edFisicaLicenc'
import { engDeProducao } from './photoDetails/engDeProducao'
import { farmacia } from './photoDetails/farmacia'
import { fundamentosDeEngenharia } from './photoDetails/fundamentosDeEngenharia'
import { livrosEngenharia } from './photoDetails/livrosEngenharia'
import { administracaoPhotos } from './photoDetails/administracao'
// import { bookPlay } from './photoDetails/bookplay'

export const photos = [
  // bookPlay,
  administracaoPhotos,
  arquiteturaPhotos,
  direitoPhotos,
  edFisicaBachaPhotos,
  edFisicaLicentPhotos,
  engDeProducao,
  farmacia,
  fundamentosDeEngenharia,
  livrosEngenharia
]
