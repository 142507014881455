export const edFisicaLicentPhotos = {
  id: 1,
  name: 'Ed. Física Licenciatura',
  featuredPhoto: 'colecaoCompletaEdFisLic.jpg',
  text: `Essa coleção conta com 20 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Primeiros Socorros no Esporte',
      photo: 'primeiroSocorroNoEsporte.jpg',
      description: `
        Nesta quinta edição, revista e atualizada, Primeiros socorros no esporte aborda de forma prática e abrangente a identificação e o tratamento de mais de 110 lesões e condições súbitas em atletas, apresentando o que se deve e o que não se deve fazer de acordo com os protocolos e recomendações das instituições mais respeitadas na área, como American Heart Association e National Athletic Trainer’s Association.

        Trata-se de uma obra imprescindível a estudantes e profissionais de educação física e medicina do esporte, treinadores, preparadores físicos e atletas, além de constituir uma excelente fonte de consulta para socorristas e técnicos de emergências médicas.

        Com uma atenção especial dirigida também à prevenção, a obra traz ainda:
        - Estratégias para reduzir os riscos de lesões ou distúrbios em atletas, como a implementação de programas de condicionamento pré-temporada.
        - A criação de ambientes seguros de jogo; planejamento para emergências associadas ao clima.
        - Garantia quanto ao uso de trajes e equipamentos protetores adequados; reforço das regras de segurança e das técnicas esportivas.
        - Desenvolvimento de um plano de emergência médica.
      `
    },
    {
      id: 2,
      name: 'Fisiologia do Exercício na Criança',
      photo: 'fisiologiaDoExericioNaCrianca.jpg',
      description: `
        Fisiologia do Exercício na Criança, em sua segunda edição, analisa não somente as questões atuais que diferenciam as crianças dos adultos, mas também seus mecanismos fundamentais. Tais diferenças são analisadas do ponto de vista fisiológico – como o tamanho, as diferenças bioquímicas e neuromusculares, a falta de maturação sexual e hormonal – e são apresentadas as razões para que elas ocorram. Os profissionais envolvidos com jovens atletas, tratamento de doenças e qualidade de vida terão acesso a uma valiosa e importante fama de informações que possibilitará a compreensão dos fatores fisiológicos determinantes no desempenho do exercício.

        Esta nova edição é dividida em 13 capítulos e apresenta as seguintes atualizações em suas características gerais:
        - Objetivos do capítulo, questões para discussão e direcionamento de pesquisa, além, de um glossário de termos.
        - O sumário foi reorganizado a fim de facilitar o avenço de um capítulo para o outro.
        - Inserção de um novo capítulo sobre a função só sistema nervoso central.

        Também está incluída uma profunda discussão sobre os fatores determinantes da aptidão aeróbia e a cinética do VO2, bem como o significado da potência aeróbia máxima nas crianças.
      `
    },
    {
      id: 3,
      name: 'Educação Física Escolar',
      photo: 'edFisicaEscolar.jpg',
      description: `
        Esta obra serve de base de consulta fundamental para todo profissional de Educação Física que quer aprimorar e modernizar a prática pedagógica da Educação Física no âmbito escolar.

        De forma didática e abrangente, a obra traz à discussão, em cada um dos seus 15 capítulos, as melhores práticas a serem utilizadas no trabalho da Educação Física Escolar nas mais diversas áreas como voleibol, handebol, basquetebol, lutas, capoeira, futebol, atletismo, atividades rítmicas, atividades aquáticas, jogos, brincadeiras, entre outras.

        Se você pretende melhorar a sistematização, a organização e a apresentação de suas aulas de Educação Física na escola, seja em que área for, esta obra poderá ser grande utilidade!
      `
    },
    {
      id: 4,
      name: 'Educando Crianças para Aptidão Física',
      photo: 'educandoCriancas.jpg',
      description: `
        Tendo em vista a atual epidemia de obesidade, um programa de educação física infantil de alta qualidade nunca foi tão necessário. Esta obra foi desenvolvida com o intuito de complementar o programa de educação física vigente por meio de estratégias e modelos pedagógicos criativos, promovendo assim a mudança necessária para transformar o estilo de vida das crianças.
        O livro fornece ferramentas essenciais não só professores de educação física, mas para todos os professores de educação infantil. Com uma abordagem multidisciplinar, que promove a colaboração com o professor de sala de aula e o envolvimento dos pais e da comunidade, o livro oferece, além, de estratégias motivacionais e de ensino, testes de aptidão física, exemplos de planos de aula, exercícios de desenvolvimento, jogos ativos e propostas para o ensino dos principais conceitos de educação física.

        Educando crianças para aptidão física – uma abordagem multidisciplinar, 2ª edição, apresenta:
        - Novas atividades rítmicas, como zumba.
        - Um capítulo inédito sobre yoga para crianças.
        - As mais recentes diretrizes de atividade física e nutrição.
        - Pesquisas e estatísticas atualizadas sobre obesidade infantil, bem como abordagens de intervenção.
        - Uma seção sobre educação física para crianças com condições especiais.
      `
    },
    {
      id: 5,
      name: 'Ensinando Dança para Crianças',
      photo: 'ensinandoDancaParaCriancas.jpg',
      description: `
        Nesta obra, os renomados autores Theresa Purcell Cone e Stephen Cone abordam a dança de maneira única, apresentando-a como uma ferramenta essencial não só para professores de educação física e dança, mas para todos os professores de educação infantil. Sua metodologia contempla a criança de forma integral, estimulando sua criatividade, consciência corporal, autocontrole, comunicação, cooperação, interações sociais positivas, além do desenvolvimento de suas habilidades motoras.

        Ensinando dança para crianças – 3ª edição apresenta:
        - 31 experiências de aprendizagem, prontas para serem colocadas em práticas, em um formato que permite uma seleção rápida e fácil;
        - 12 atividades para crianças da pré-escola ao segundo ano do ensino fundamental;
        - 11 atividades para alunos do terceiro ao quinto ano;
        - 8 experiências de dança elaboradas para aulas inclusivas ou autônomas.

        Descritas de forma detalhada e dinâmica, as experiências de aprendizagem propostas no livro incluem: resultados relativos aos aspectos psicomotor, cognitivo e afetivo; equipamentos necessários; ideias para a introdução, o desenvolvimento e a finalização da dança; e sugestões de avaliação. Completo, o livro traz ainda sugestões para o ensino interdisciplinar.
        O leitor também aprenderá a elaborar e apresentar um programa de dança, assim como adaptá-lo de acordo com o cenário de ensino e a adotar estratégias para a manutenção e o desenvolvimento contínuo do programa.
      `
    },
    {
      id: 6,
      name: 'Ensinando Ginástica para Crianças',
      photo: 'ensinandoGinasticaParaCriancas.jpg',
      description: `
        Com novas propostas de experiências de aprendizagem, esta terceira edição de Ensinando ginástica para crianças reúne um excelente material sobre conteúdo e sequência de aulas para alunos a partir do primeiro ano do ensino fundamental, com dezenas de atividades lúdicas e criativas, e mostra ainda como elaborar um programa inclusivo, orientando professores e instrutores na criação de um ambiente de ensino altamente positivo.
        Escrito por autores de renome internacional, esta obra auxiliará professores de educação física e instrutores de ginástica a ensinar habilidades individuais aos alunos sob três diferentes prismas: deslocamento, equilíbrio estático e rotação.

        Ensinando ginástica para crianças – 3ª edição apresenta:
        - orientações para o desenvolvimento de um programa de ginástica específico para cada situação de ensino;
        - novo material sobre gerenciamento de comportamento, inclusão, mediação de conflitos e criação de séries;
        - objetivos, resumos e questões para reflexão em cada capítulo, que ajudam a aplicar o conteúdo e a aperfeiçoar a experiência de aprendizagem;
        - dicas de especialistas para planejamento do programa, incorporação ao currículo e avaliação dos alunos.

        Trata-se de um livro essencial tanto para professores iniciantes como para os mais experientes. Mais do que isso, é o recurso perfeito para apresentar o mundo divertido e fascinante da ginástica artística a crianças e jovens.
      `
    },
    {
      id: 7,
      name: 'Pedagogia do Esporte',
      photo: 'pedagogiaDoEsporte.jpg',
      description: `
        A Pedagogia do Esporte nas últimas décadas, com base em um incremento no número e na qualidade de estudos produzidos, vem se consolidando enquanto significativa área do conhecimento no interior das Ciências do Esporte. Esse aumento nas produções tem gerado significativos avanços nas reflexões acerca de temas, como metodologias de ensino e de treinamentos de esportes, aprendizagem, didática, análises de jogos, compreensão do esporte como um processo e produto cultural, entre outros.

        Desse modo, as novas tendências em Pedagogia do Esporte procuram entender o esporte para além de suas máximas populares, como, “o esporte é bom por natureza”, “o esporte afasta as drogas”, “o esporte tira as crianças da rua”. Logo cabe à Pedagogia do Esporte, em suas mais diversas facetas, que vão desde investigações sobre a iniciação esportiva ao treinamento de alto rendimento, passando pela formação de professores e treinamento de alto rendimento, passando pela formação de professores e treinadores, gerar conhecimentos concretos, que permitam a superação de ideias e comportamentos do senso comum.
      `
    },
    {
      id: 8,
      name: 'Anatomia do Alongamento',
      photo: 'anatomiaDoAlongamento.jpg',
      description: `
        Anatomia do Alongamento mostra como aumentar a amplitude de movimento, focando sua eficiência máxima, além de mostrar como complementar o treinamento e melhorar a recuperação. Pode-se dizer que esta obra faz um verdadeiro raio x de cada exercício: ilustrações coloridas de alta qualidade destacam os grupos musculares em ação, com informações de como o alongamento afeta o corpo e de como as alterações na posição podem alterar a ênfase muscular e o grau de dificuldade do exercício, além de aumentar a segurança e a eficácia.

        Os alongamentos estão agrupados pela parte do corpo a que se destinam, nos seguintes capítulos:
        - Pescoço
        - Ombros, Costas e Tórax
        - Braços, Punhos e Mãos
        - Parte Inferior do tronco
        - Quadris
        - Joelhos e Coxas
        - Pés e Panturrilhas

        Cada um dos exercícios inclui instruções detalhadas sobre o modo e o momento certo de alongar, sobre as ênfases musculares primária e secundária e os músculos que são ativados para apoio. Os programas de alongamento apresentam três níveis de dificuldade; inclusive o suave, que pode ser usado como aquecimento ou para ajudar na recuperação de músculos doloridos ou lesados
        As tabelas de movimentos ensinam a personalizar programas de alongamento de modo a enfocar áreas problemáticas importantes.
        Seja para aumentar a flexibilidade, reduzir dores ou tensões musculares, Anatomia do Alongamento permite que o leitor aprenda e sinta os benefícios da aplicação das técnicas mais eficazes e adequadas de alongamento.
      `
    },
    {
      id: 9,
      name: 'Atividades Físicas',
      photo: 'atividadesFisicas.jpg',
      description: `
        Atividades físicas para jovens com deficiências graves constitui uma referência imprescindível para professores de educação física adaptada, especialistas em terapia recreativa, fisiatras, fisioterapeutas, bem como para todos os profissionais interessados em desenvolver atividades físicas e recreacionais voltadas para alunos com paralisia cerebral, espinha bífida e outras deficiências físicas.

        Por meio de uma abordagem dinâmica e em formato prático de consulta, este livro traz 50 atividades físicas envolventes, explicadas passo a passo e acompanhadas de fotografias. Com o objetivo de promover uma educação física acessível e de alta qualidade, as práticas propostas neste guia apresentam ainda:
        - foco no aperfeiçoamento da coordenação e movimentação espacial, equilíbrio e flexibilidade, fortalecimento muscular e resistência cardiorrespiratória;
        - variações de nível de dificuldade, do mais fácil para o mais complexo, em cada atividade;
        - uso de objetos comuns como equipamentos;
        - atividades que podem ser aplicadas em uma variedade de ambientes;
        - eficácia comprovada por meio de pesquisas baseadas em evidências que apontam os resultados benéficos da atividade física para pessoas com deficiências.

        Independentemente das limitações que os alunos possuam, esta obra possibilita ao educador criar um programa especialmente adaptado que considere a condição física, as necessidades e as habilidades de forma individual, proporcionando assim diversos benefícios físicos e psicológicos que melhorarão a qualidade de vida dos jovens com deficiências físicas de uma maneira global.
      `
    },
    {
      id: 10,
      name: 'Futebol e Futsal',
      photo: 'futebolEFutsal.jpg',
      description: `
        Futebol e futsal são modalidades esportivas praticadas em todo o território brasileiro e são muitos parecidas; têm os mesmos objetivos, porém, cada uma tem particularidades e exigências próprias. Homens e mulheres dividem situações de aprendizagem desde a iniciação até o alto rendimento.

        Este livro relata várias situações e explica modelos de jogos, tipos de treinamento e atividades aplicadas no futebol e no futsal, com destaque à aplicação para o futebol feminino, categoria de destaque mundial nos dias de hoje.

        Seu conteúdo, escrito em linguagem simples e eficaz, contribuí para o aprimoramento do desenvolvimento técnico individual e coletivo dos praticantes, bem como profissionais ligados à área.

        Se você é um deles, não pode perder esta leitura!
      `
    },
    {
      id: 11,
      name: 'Handebol',
      photo: 'handebol.jpg',
      description: `
      - Qual é a história do handebol até tornar-se o jogo que conhecemos atualmente?
      - Quais os principais torneios de handebol no Brasil e no mundo?
      - Como se joga handebol no Brasil e no mundo?
      - Como se joga handebol e quais são suas regras?
      - Que elementos técnicos e táticos são utilizados no ataque e na defesa?
      - Como o goleiro de handebol atua técnica e taticamente?

      Essas perguntas são respondidas de maneira objetiva e didática em Handebol: conceitos e aplicações, uma excelente leitura para estudantes e profissionais em busca de informações sobre essa modalidade esportiva tão praticada em todo o mundo.
      “Handebol: conceitos e aplicações segue uma perspectiva de afastamento dos convencionalismos existentes nos livros-texto, mas deixa clara a teoria relacionada com os conteúdos inerentes à modalidade.” – Pablo Juan Greco
      `
    },
    {
      id: 12,
      name: 'Ensinando Tênis para Jovens',
      photo: 'ensinandoTenis.jpg',
      description: `
        “Este é um livro excelente para treinadores que querem ajudar seus jogadores no desenvolvimento de habilidades e mantê-los livres de acidentes.”
        Paul Roetert – Diretor de Ciência Esportiva United States Tennis Association

        “Este livro é um guia claro e objetivo para treinar jovens para o tênis, além de incluir atividades que tornam os reinos divertidos!”
        Ron Woods – Diretor de Tênis para a Juventude United States Tennis Association

        Ensinando Tênis para Jovens é um guia excelente para trabalho com atletas de 6 a 14 anos. Explica como ensinar aos jovens as técnicas e as estratégias importantes de tênis em um nível perfeitamente adequado para eles.
        Este valioso recurso para o treinador explica os métodos mais eficazes do ensino dos elementos fundamentais do tênis. Os pais e os treinadores, mesmo durante sua primeira temporada, encontrarão todas as informações de que necessitam para um bom trabalho com os jovens.
      `
    },
    {
      id: 13,
      name: 'Ensinando Voleibol para Jovens',
      photo: 'ensinandoBoleibol.jpg',
      description: `
        “Este livro é essencial para técnicos que estão começando a treinar jovens menores de 14 anos, que querem a melhor iniciação no voleibol.”
        John Kessell – Diretor de Cursos do USA Volleyball

        “Fácil de ser lida, esta obra é uma excelente introdução para técnicos iniciantes e também uma ótima revisão para técnicos experientes. Gosto do enfoque do livro, que enfatiza a importância de as crianças adquirirem experiência positiva no esporte.”
        Ruben Nieves - Técnico do time masculino de voleibol da Stanford University

        Ensinando Voleibol para Jovens é uma ótima referência para técnicos que trabalham com atletas de 6 a 14 anos. Ele descreve, em detalhes, como ensinar habilidades importantes do voleibol e estratégias adequadas aso jovens jogadores.
        Este livro contém 23 exercícios e mais de 80 ilustrações para ensinar, passo a passo, as habilidades básicas do voleibol – o que ensinar e como ensinar. Nesta segunda edição foi acrescentado o jogo completo de seis contra seis, e as regras e especificidades do voleibol e de grama.
      `
    },
    {
      id: 14,
      name: 'Ensinando Basquetebol para Jovens',
      photo: 'ensinandoBasquete.jpg',
      description: `
        “Sem dúvida, recomendo Ensinando Basquetebol para Jovens. É o livro ideal para técnicos iniciantes de basquetebol.”
        Larry E. Walker – Presidente e Fundador do Basketball Congress International

        “Ensinando Basquetebol para Jovens é um guia excelente para orientar técnicos de basquetebol infantil. Finalmente, um livro prático e com explicações passo a passo que posso recomendar para nossos técnicos voluntários de esporte amador!”
        Lynn Burkey – Presidente do Girls Basketball Advisory Committee Amateur Athletic Union

        Ensinando Basquetebol para Jovens é uma ótima ferramenta para pais e técnicos de basquetebol infantil que trabalham com atletas de 6 a14 anos. Ele descreve detalhadamente como ensinar aos jovens as técnicas e as estratégias importantes do basquetebol em um nível perfeitamente adequado para eles.
        Este valioso recurso apresenta métodos para ensinar passo a passo as técnicas fundamentais do basquetebol - o que e como ensinar. Esta segunda edição classifica cada exercício em básico, intermediário ou avançado, ajudando-o a escolher aqueles que são adequados para o nível de habilidade de seus jogadores.
      `
    },
    {
      id: 15,
      name: 'Esporte e Educação 6º Ano',
      photo: 'esporteEducacao6ano.jpg',
      description: `
        Esta coleção traz a proposta de se trabalhar uma unidade por bimestre, proporcionando ao aluno e ao professor tempo suficiente para a assimilação do conteúdo e sua aplicação prática.

        No volume dedicado ao 6º ano – Corpo em movimento – são trabalhadas as modalidades: basquetebol, voleibol e handebol; com uma proposta de gincana ao final do último bimestre. Contém visão geral sobre o corpo e sua anatomia, orientações sobre postura, força, agilidade e ritmo.
      `
    },
    {
      id: 16,
      name: 'Esporte e Educação 7º Ano',
      photo: 'esporteEducacao7ano.jpg',
      description: `
        Esta coleção traz a proposta de se trabalhar uma unidade por bimestre, proporcionando ao aluno e ao professor tempo suficiente para a assimilação do conteúdo e sua aplicação prática.

        No volume dedicado ao 7º ano – Meio ambiente e atividade física – são trabalhadas as modalidades: atletismo, xadrez e natação; com uma proposta de olimpíadas escolares ao final do último bimestre. Aborda a relação que o homem estabelece com a natureza, esportes da mente e esportes radicais.
      `
    },
    {
      id: 17,
      name: 'Esporte e Educação 8º Ano',
      photo: 'esporteEducacao8ano.jpg',
      description: `
        Esta coleção traz a proposta de se trabalhar uma unidade por bimestre, proporcionando ao aluno e ao professor tempo suficiente para a assimilação do conteúdo e sua aplicação prática.

        No volume dedicado ao 8º ano – Esporte e ciência – são trabalhadas as modalidades: futsal, triátlon, rúgbi e artes marciais.

        Discorre sobre anatomia e fisiologia, e fala sobre a importância de escolher bem os alimentos.
      `
    },
    {
      id: 18,
      name: 'Esporte e Educação 9º Ano',
      photo: 'esporteEducacao9ano.jpg',
      description: `
        Esta coleção traz a proposta de se trabalhar uma unidade por bimestre, proporcionando ao aluno e ao professor tempo suficiente para a assimilação do conteúdo e sua aplicação prática.

        No volume dedicado ao 9º ano – O esporte e a mente – são trabalhadas as modalidades: tênis de mesa, capoeira e ginástica. Aborda os aspectos mentais que envolvem os esportes, uso de anabolizantes e ergonomia.
      `
    },
    {
      id: 19,
      name: 'Esporte e Educação Manual do Educador',
      photo: 'esporteEducacaoManualEducador.jpg',
      description: `
        Esta coleção traz a proposta de se trabalhar uma unidade por bimestre, proporcionando ao aluno e ao professor tempo suficiente para a assimilação do conteúdo e sua aplicação prática.

        No volume dedicado ao educador o conteúdo recebe um suporte didático e pedagógico, com a função de otimizar a experiência em sala de aula e proporcionar um aprendizado mais dinâmico aos alunos.
      `
    },
    {
      id: 20,
      name: 'Esporte e Educação Ensino Médio',
      photo: 'esporteEducacaoEnsinoMedio.jpg',
      description: `
        Esta coleção traz a proposta de se trabalhar uma unidade por bimestre, proporcionando ao aluno e ao professor tempo suficiente para a assimilação do conteúdo e sua aplicação prática.

        No volume dedicado ao Ensino Médio, as modalidades e os conteúdos trabalhados no Ensino Fundamental ganham maior profundidade, proporcionando ao aluno dinâmicas e vivências esportivas mais completas.
      `
    }
  ]
}
