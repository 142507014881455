import styled from 'styled-components'
import media from 'styled-media-query'
import { darken } from 'polished'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #030517;
  border-radius: 0.4rem;
  background-color: #fff;
  padding: 2.2rem 0.5rem;
  margin: 2.2rem 0;
  min-height: 26rem;
  max-width: 100%;

  ${media.lessThan('1045px')`
    padding: 0;
    margin: 2.2rem 0;
    min-height: 26rem;
  `}
`

export const User = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > div.gatsby-image-wrapper {
    width: 12rem;
    height: 12rem;

    max-width: 12rem;
    max-height: 12rem;
  }
`

export const Image = styled.img`
  width: 12rem;
  height: 12rem;
`

export const Name = styled.p`
  color: rgb(67, 64, 104);
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 1.6rem;
  text-align: center;
  height: 48px;
  display: flex;
  align-items: center;

  ${media.lessThan('980px')`
    margin-top: 1rem;
    font-size: 1rem;
  `}
`

export const Text = styled.span`
  display: flex;
  margin-top: 1.2rem;
  font-size: 1.2rem;
  position: relative;
  color: rgb(67, 64, 104);

  ${media.lessThan('980px')`
    margin-top: 0.8rem;
  `}

  > p {
    text-align: center;
    padding-top: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5rem;

    ${media.lessThan('1045px')`
      font-size: 1.2rem;
      padding: 0.7rem;
    `}

    ${media.lessThan('980px')`
      font-size: 0.9rem;
    `}
  }
`

export const Details = styled.span`
  margin-top: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  background: #3ad586;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;

  transition: 0.3s transform;
  &:hover {
    background: ${darken(0.06, '#3ad586')};
    transform: scale(1.02);
  }
`
