import React, { useState } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import ReviewCardModal from '../ReviewCardModal'
import DrawingModal from '../../images/drawing-modal.png'
import { links } from './links'

import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

import * as S from './styles'

const settings = {
  dots: true,
  arrows: false,
  slidesToShow: 1,
  infinite: false,
  speed: 500,
  rows: 1,
  slidesPerRow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        rows: 1,
        slidesPerRow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export default function ModalProductDetails({
  fnSetModalDetailProduct,
  menuOpened,
  collectionSelected
}) {
  const [{ name, text, photoDetails }] = useState(collectionSelected)

  return (
    <S.ModalProductDetails>
      <S.ModalContainer>
        <S.CloseModal onClick={() => fnSetModalDetailProduct(!menuOpened)}>
          <CloseOutline />
        </S.CloseModal>
        <S.ProductFixedTitleContainer>
          <S.ProductFixedTitle>{name}</S.ProductFixedTitle>
          <S.ProductFixedDescription>{text}</S.ProductFixedDescription>
          <S.ButtonImInterested
            href={links(name).url}
            target="_blank"
            rel="noreferrer"
          >
            Tenho interesse
          </S.ButtonImInterested>

          <S.Drawing src={DrawingModal} alt="Desenho pessoa estudando" />
        </S.ProductFixedTitleContainer>

        <S.ContainerCarousel>
          <S.Content>
            <Slider {...settings}>
              {photoDetails.map(({ id, name, photo, description }) => (
                <ReviewCardModal
                  key={id}
                  name={name}
                  photo={photo}
                  text={description}
                  id={id}
                />
              ))}
            </Slider>
          </S.Content>
        </S.ContainerCarousel>
      </S.ModalContainer>
    </S.ModalProductDetails>
  )
}

ModalProductDetails.propTypes = {
  fnSetModalDetailProduct: PropTypes.func.isRequired,
  menuOpened: PropTypes.bool.isRequired,
  collectionSelected: PropTypes.object.isRequired
}
