import styled from 'styled-components'

import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  flex: 1 1 280px;
  margin: 2rem 0;
  height: 12rem;
  max-width: 390px;

  ${media.lessThan('large')`
    margin: 1rem 0;
  `}
`

export const Image = styled.img`
  position: absolute;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`
export const StepNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2.5rem;
  width: 60px;
  height: 60px;

  border-radius: 50%;

  color: #fff;
  background: ${(props) => props.color};
  z-index: 1;

  ${media.lessThan('large')`
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;
  `}
`

export const StepTitle = styled.h2`
  margin: 1rem 0;
  z-index: 1;
  font-size: 1.4rem;
  color: rgb(67, 64, 104);

  ${media.lessThan('large')`
    font-size: 1rem;
    margin: 0 0 1rem 0;
  `}
`

export const StepDescription = styled.p`
  font-size: 1rem;
  text-align: center;
  z-index: 1;
  color: rgb(67, 64, 104);
  max-width: 80%;

  ${media.lessThan('1045px')`
    font-size: 1rem;
  `}
`
