const replaceSpacesToLink = (message) => {
  return message.replace(/\s+/g, '%20')
}

const createLinkWhatsapp = (numberPhone, message) => {
  const formatedString = replaceSpacesToLink(message)
  const url = `https://wa.me/${numberPhone}?text=${formatedString}`

  return url
}

export const links = (collectionName) => {
  return {
    label: 'Whatsapp',
    url: createLinkWhatsapp(
      '5518996362183',
      `Olá, cheguei no seu número através do site, estou interessado na coleção
       de ${collectionName}`
    ),
    color: '#00E676'
  }
}
