export function scrollToIdOnClick(targetId) {
  const target = document.getElementById(targetId).offsetTop

  if (typeof window != 'undefined') {
    window.scroll({
      top: target,
      behavior: 'smooth'
    })
  }
}
