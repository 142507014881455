export const direitoPhotos = {
  id: 1,
  name: 'Direito',
  featuredPhoto: 'colecao_completa_2021.jpg',
  text: `Essa coleção conta com 12 livros incríveis para auxiliar os seus estudos.`,
  photoDetails: [
    {
      id: 1,
      name: 'Código Civil',
      photo: 'codigo_civil.jpg',
      description: `
        Esta obra constitui um importante instrumento para a atuação do operador do
        Direito, fornecendo apoio indispensável no manejo do Código Civil brasileiro.

        Em comentários sistematizados e objetivos, autores de reconhecida importância
        na doutrina civilista brasileira realizam, sob a coordenação do Ministro Cezar Peluso,
        um exame crítico do Código Civil, artigo por artigo, à luz das mudanças legislativas e do
        posicionamento mais recente dos principais tribunais do país.
        A indicação da legislação correlata e a transcrição de trechos de acórdãos pertinentes
        aos respectivos temas complementam a análise dos dispositivos.
        Visando oferecer ao profissional do Direito uma fonte de informação consistente e atualizada
        diante dos desafios que exijam o conhecimento da nossa codificação civil, esta 15ª edição contém
        atualizações promovidas até a Lei n.14.030/2020.


        Autor: Ministro Cezar Peluso
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 7,6cm x 17,5cm x 24,7cm
        Páginas: 2393
      `
    },
    {
      id: 2,
      name: 'Manual do Advogado',
      photo: 'manual_do_advogado.jpg',
      description: `
        O Manual do Advogado preserva, em sua essência, o cuidadoso conteúdo que justifica
        o sucesso de tantas edições ao longo dos anos. Com abordagem didática e em linguagem
        simples, a obra se mantém como uma das mais prestigiadas ferramentas de apoio aos advogados e, especialmente, aos iniciantes nas atividades forenses, fornecendo informações
        imprescindíveis para o bom desempenho da profissão.
        Em sua 33º edição, revisada e atualizada, o livro aborda temas da maior relevância, como petição inicial, providências que antecedem o ajuizamento da ação, defesa do réu, audiência, sentença e recursos. Os inúmeros modelos de petições, contestações e recursos complementam a teoria e atuam como suporte eficiente no exercício da advocacia cível, trabalhista e criminal.
        A obra oferece ainda informações relevantes como uma explicação prática sobre os prazos no CPC, noções essenciais sobre a pós-graduação em direito no Brasil, um dicionário com as expressões latinas mais usuais, um vocabulário jurídico, a íntegra do Estatuto da Advocacia e a OAB e do Código de Ética e Disciplina da Ordem.
        Obra útil para o estudante de Direito, que contará com ensinamentos valiosos para o sucesso na graduação, e também para o bacharel que se prepara para o Exame da Ordem e para o ingresso na carreira jurídica.
        Esta edição conta capítulo novo sobre Advocacia em tempos de Covid-19, escrito pelo Dr. Mauricio Jalil.


        Autor: Valdemar P. da Luz
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 4,2cm x 16,7cm x 24,0cm
        Páginas: 1082
      `
    },
    {
      id: 3,
      name: 'O caso dos exploradores de cavernas',
      photo: 'o_caso_dos_exploradores_de_cavernas.jpg',
      description: `
        Obra clássica à Ciência e Filosofia do Direito

        “Recomendo aos(ás) colegas docentes o emprego desta obra
        em sala de aula, considerando-a um atual e potente método
        de ensino – que abrange pesquisa, debate, participação,
        opinião, argumentação, uso das fontes do Direito -, ao
        envolver os estudantes nas fissuras mais profundas dessa
        imensa caverna de dúvidas que temos, quando o Direito
        se torna nosso objeto de busca, inquietação, investigação,
        estudo e discussão, algo com que a Ciência do Direito não
        está livre de ter de, ainda hoje, desafiadoramente lidar.’’


        Autor: Lon L. Fuller
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 0,4cm x 12,7cm x 17,9cm
        Páginas: 54
      `
    },
    {
      id: 4,
      name: 'Manual de Direito Civil',
      photo: 'manual_de_direito_divil.jpg',
      description: `
        Fundamental para operadores do direito e candidatos ao exame da Ordem
        e a concursos públicos em geral
        São mais de 100 questões comentadas

        O Manual de direito civil reúne, em uma único volume, todos os assuntos
        tratados nos nove livros do Código Civil brasileiro. Com o objetivo de
        facilitar o estudo dessa disciplina e o ingresso na profissão jurídica, a obra apresenta:

        • Questões de concursos e Exames da Ordem disponibilizadas logo após a matéria tratada,
        incluindo remissões aos dispositivos do Código Civil e às súmulas em que os respectivos temas
        são abordados;

        • tabelas com resumos da matéria, para facilitar a visão ampla dos institutos do direito civil;

        • quadro comparativo sobre o tema incapacidade, que foi alterado pelo Estatuto da Pessoa
        com Deficiência e pelo Código de Processo Civil;

        • texto atualizado de acordo com as Leis ns.14.010 (normas de caráter emergencial e transitório
        para a regulação de relações jurídicas no contexto da pandemia) e 14.030 (acrescentou o art.1.080-A
        no Código Civil, dispondo sobre votação a distância em assembleias por parte de sócio no contexto da pandemia),
        além dos enunciados das Jornadas de Direito Civil do conselho da Justiça Federal e das súmulas do STJ que abordam os temas de direito civil.


        Autor: Luiz Fernando do Vale de Almeida Guilherme
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 2,0cm x 17,0cm x 24,0cm
        Páginas: 512
      `
    },
    {
      id: 5,
      name: 'Dicionário Jurídico',
      photo: 'dicionario_juridico.jpg',
      description: `
        O Dicionário Jurídico do Professor Valdemar P. da Luz consagrou-se
        como ferramenta de grande utilidade prática tanto para o estudante
        da graduação em Direito como para o candidato a concursos
        públicos em geral. Seu formato reduzido, que facilita o manuseio,
        aliado a uma seleção rigorosa de temas de grande relevância, torna
        esta obra indispensável.

        Os verbetes abrangem todas as áreas do Direito e os campos
        correlatos, e suas definições se baseiam em textos legais, sem
        dispensar as mais autorizadas fontes da doutrina e da jurisprudência
        brasileiras. A linguagem simples, direta e ao mesmo tempo precisa
        favorece a compreensão rápida e a fácil assimilação do conteúdo.

        A 4ª ediçãofoi atualizada com verbetes referentes á pandemia de
        Covid-19, sempre segundo as mais recentes inovações na legislação
        brasileira. Estão presentes, entre outras, as definições de auxílio
        emergencial, isolamento e teletrabalho.

        Autor: Valdemar P. da Luz
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 1,8cm x 12,8cm x 17,9cm
        Páginas: 398
      `
    },
    {
      id: 6,
      name: 'Direito Constitucional',
      photo: 'direito_constitucional.jpg',
      description: `
        Direito Constitucional chega a sua terceira edição revisada e atualizada
        como uma obra que versa sobre as questões mais polêmicas
        e recorrentes do exame da OAB e demais concursos públicos. O Livro,
        voltado a estudantes, está estruturado de forma a proporcionar
        praticidade nas consultas e concisão na leitura . Além disso, enfoca-se
        a doutrina de maior relevo no cenário nacional com abordagens
        claras sobre os diversos institutos existentes em nossa Constituição Federal.
        A jurisprudência mais relevante e atual também é objeto
        de atenção, sobretudo as decisões que suscitaram controvérsias e
        viabilizaram críticas doutrinárias.
        Esta nova edição não deixa de contemplar também o principal fato do último
        ano, a pandemia da Covid-19, abordando as consequências constitucionais
        causadas por esta situação excepcional .
        A obra, enfim, destina-se estudante que busca um material completo, atualizado
        e prático para seus estudos.

        Autor: Edson Ricardo Saleme
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 1,5cm x 15,5cm x 22,9cm
        Páginas: 364
      `
    },
    {
      id: 7,
      name: 'CLT Interpretada',
      photo: 'CLT.jpg',
      description: `
        CLT interpretada – artigo por artigo, parágrafo por parágrafo, agora em
        12ª edição, sob a organização do Professor Costa Machado e coordenação
        do Professor Domingos Sávio Zainaghi, faz parte da bem-sucedida linha
        de Códigos interpretados em formato reduzido da Editora Manole.
        Trata-se de obra Didática, completa e fácil de manusear.
        Didática porque, sendo a maioria de seus autores professores de Direito do
        Trabalho e/ou Direito Processual do Trabalho, incluindo o coordenador,
        a preocupação pedagógica é uma constante, o que torna a obra um sucedâneo dos manuais existentes nas livrarias jurídicas.

        Completa porque corresponde ao único trabalho disponível no mercado
        que realmente apresenta interpretações artigo por artigo, parágrafo por
        parágrafo da CLT.

        Fácil de manusear e transportar por conta do seu formato reduzido,
        que permite ao aluno levar todos os dias a sua CLT interpretada para a
        faculdade e ao profissional levá-la para o fórum e suas audiências.


        Autor: Adalberto Martins / Costa Machado / Domingos Sávio Zainaghi
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 4,5cm x 12,8cm x 17,8cm
        Páginas: 1201
      `
    },
    {
      id: 8,
      name: 'Curso de Introdução ao Direito',
      photo: 'curso_introducao_direito.jpg',
      description: `
        Curso de introdução ao direito – em 13 aulas, de autoria do Professor
        Luiz Fernando Coelho, preenche uma lacuna no mercado jurídico-
        editorial: praticidade para os que necessitam de noções fundamentais
        nas disciplinas de introdução ao Estudo do Direito, Teoria Geral do
        Direito e Filosofia do Direito, tanto em nível de graduação
        como de pós-graduação.
        Esta obra apresenta em treze aulas, equivalente a capítulos, um
        texto em ordem progressiva, com os pressupostos indispensáveis
        a uma sólida base de conhecimentos, expondo com clareza didática
        os aspectos mais relevantes das disciplinas propedêuticas do curso
        de Direito. Além disso, visa facilitar aos acadêmicos, bacharéis,
        advogados, professores, procuradores federais e estaduais – que
        não se contentariam com o simples repisar de temas já estudados
        -compreensão mais abrangente, macrofilosófica, do fenômeno
        jurídico, político e social.

        Autor: Luiz Fernando Coelho
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 1,3cm x 15,5cm x 22,6cm
        Páginas: 271
      `
    },
    {
      id: 9,
      name: 'VADE MECUM Universitário',
      photo: 'manualDeDireitoCivil.jpg',
      description: `
        Novidades desta Edição:
        CF atualizada até a EC n. 108
        CC de acordo com as Lei n. 14.030
        CP de acordo com as Lei n. 14.110
        CTB de acordo com as Lei n. 14.071
        Leis de enfrentamento ao coronavírus: Lei n.13.979 e Lei Complementar n.173
        Lei de enfrentamento á violência doméstica e familiar durante pandemia: Lei n 14.022
        Lei n. 11.101 atualizada com a Lei n.14.112

        Autor: Editoria Jurídica da Editora Manole
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 6,7cm x 16,2cm x 23,5cm
        Páginas: 1692
      `
    },
    {
      id: 10,
      name: 'Código Penal',
      photo: 'codigo_penal.jpg',
      description: `
        O Código Penal comentado – doutrina e jurisprudência chega a sua 4ª edição
        consagrado como referência de clareza, abrangência e rigor técnico no estudo
        do Direito Penal brasileiro.
        Sob a coordenação dos Professores Mauricio Schaun Jalil e Vicente Greco Filho,
        um elenco de renomados penalistas – juízes, procuradores, advogados
        e professores – uniu sua experiência para oferecer ao leitor uma análise
        aprofundada de cada um dos dispositivos do Código Penal, considerando
        as mais recentes atualizações e seus desdobramentos.
        A metodologia dos comentários confere à obra a profundidade necessária
        para atender não só aos profissionais do Direito como aos estudantes da
        graduação e da pós-graduação.
        Todos os autores lastreiam sua análise na mais relevante doutrina nacional e
        estrangeira e na jurisprudência recente dos principais tribunais do País, sempre
        visando oferecer ao leitor interpretação segura e consistente da norma penal brasileira.
        A nova edição está atualizada de acordo com a Lei n. 14.110/2020 (sobre denunciação caluniosa).


        Autor: Acácio Miranda da Silva Filho / Maurício Schaun Jalil / Vicente Greco Filho
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 3,9cm x 17,0cm x 23,9cm
        Páginas: 1038
      `
    },
    {
      id: 11,
      name: 'Constituição Federal',
      photo: 'constituicao_federal.jpg',
      description: `
        Constituição Federal Interpretada - Artigo por artigo, parágrafo por parágrafo, agora em
        12ª edição, sob a organização do Professor Costa
        Machado e coordenação da Professora Anna Cândida da Cunha
        Ferraz, faz parte da bem-sucedida linha de códigos interpretados em
        formato reduzido da Editora Manole.
        Trata-se de obra didática, completa e fácil de manusear.

        Didática porque, sendo seus autores professores de Direito
        Constitucional, incluindo a coordenadora, a preocupação pedagógica
        é uma constante, o que faz da obra um sucedâneo dos manuais
        existentes nas livrarias jurídicas.

        Completa porque corresponde ao único trabalho disponível no
        mercado que realmente apresenta interpretações artigo por artigo,
        parágrafo por parágrafo da Constituição Federal.

        Fácil de manusear e transportar, por conta do seu formato reduzido,
        permite ao aluno levar todos os dias a sua Constituição Federal
        interpretada para a faculdade e ao profissional levá-la para o fórum
        e suas audiências.


        Autor: Adriana Zawada Melo / Costa Machado / Anna Candida da Cunha Ferraz
        Editora: MANOLE EDITORA
        Dimensões (LxCxA): 5,5cm x 12,7cm x 17,8cm
        Páginas: 1499
      `
    },
    {
      id: 12,
      name: 'Prática Penal',
      photo: 'pratica_penal.jpg',
      description: `
        A partir da experiência docente dos autores, aliados à vivência prática
        como Delegado Federal e Magistrado, a obra procurou suprir uma lacuna no
        meio jurídico: um livro que fornecesse, de forma objetiva, clara e didática, a
        teoria e a prática necessárias para a 2ª fase do Exame da OAB e, ao mesmo
        tempo, servisse como um referencial de consulta aos operadores do Direito
        em suas atuações nas lides criminais, desde a fase de inquérito, o processo de
        conhecimento e suas fases recursais, bem como na execução penal.
        Assim, o leitor encontrará neste livro toda a parte teórica do Direito
        Penal, Processo Penal e legislações penais e processuais extravagantes
        necessárias às formulações de suas teses e objetos de indagações no Exame
        da OAB, além de modelos de peças práticas com explicações didáticas, jurisprudência
        atualizada dos Tribunais Superiores e as questões dos exames da
        OAB com respectivos gabaritos.

        Autor: Carlos Paschoalik Antunes / Marcelo Yukio Misaka
        Editora: Stábile Editora
        Dimensões (LxCxA): 2,3cm x 15,7cm x 23,0cm
        Páginas: 491
      `
    }
  ]
}
