import styled from 'styled-components'
import media from 'styled-media-query'

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TopDivisor = styled.img`
  height: 2rem;
  transform: rotate(180deg);

  ${media.lessThan('large')`
    height: 0.7rem;
  `}
`

export const StepTitle = styled.h2`
  margin: 2rem 0;
  font-size: 1.7rem;
  text-align: center;
  color: rgb(67, 64, 104);

  ${media.lessThan('large')`
    font-size: 1.5rem;
  `}
`

export const StepContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;

  ${media.lessThan('large')`
    justify-content: center;
  `}
`

export const BottonDivisor = styled.img`
  height: 2rem;

  ${media.lessThan('large')`
    height: 0.7rem;
  `}
`

export const StepData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1480px;
  margin: 0 auto;
  width: 100%;
`
