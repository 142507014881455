import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

function Button({ label, onClick }) {
  return (
    <S.ButtonWrapper onClick={onClick}>
      <S.ButtonLabel>{label}</S.ButtonLabel>
    </S.ButtonWrapper>
  )
}

Button.defaultProps = {
  onClick: () => {}
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default Button
