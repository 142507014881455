import React from 'react'
import PropTypes from 'prop-types'

import { scrollToIdOnClick } from '../../utils/scrollToSection'

import * as S from './styles'

const OptionsList = ({ menuPhone, fnHandleModalMenu }) => {
  const handleClick = (section) => {
    switch (section) {
      case 'buyNow':
        scrollToIdOnClick('contact-section')
        break

      case 'collections':
        scrollToIdOnClick('collections-section')
        break
    }

    fnHandleModalMenu(false)
  }

  return (
    <S.ModalOptionsList menuPhone={menuPhone}>
      <S.ModalOptionItem onClick={() => handleClick('buyNow')}>
        ADQUIRA JÁ
      </S.ModalOptionItem>
      <S.ModalOptionItem onClick={() => handleClick('collections')}>
        COLEÇÕES
      </S.ModalOptionItem>
      <S.ModalOptionItem onClick={() => handleClick('buyNow')} spotlight>
        TENHO INTERESSE
      </S.ModalOptionItem>
    </S.ModalOptionsList>
  )
}

function ModalMenuPhone({ menuPhone, fnHandleModalMenu }) {
  return (
    <S.ModalWrapper menuPhone={menuPhone}>
      {menuPhone && (
        <OptionsList
          menuPhone={menuPhone}
          fnHandleModalMenu={fnHandleModalMenu}
        />
      )}
    </S.ModalWrapper>
  )
}

ModalMenuPhone.propTypes = {
  menuPhone: PropTypes.bool.isRequired,
  fnHandleModalMenu: PropTypes.func.isRequired
}

OptionsList.propTypes = {
  menuPhone: PropTypes.bool.isRequired,
  fnHandleModalMenu: PropTypes.func.isRequired
}

export default ModalMenuPhone
