import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'
import { darken } from 'polished'

export const ContactWrapper = styled.section`
  width: 100%;
  background: #efeeff;
`

export const ContactContainer = styled.div`
  max-width: 1480px;
  margin: 0 auto;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  margin-bottom: 1.2em;
  font-size: 1.7rem;
  text-align: center;
  color: rgb(67, 64, 104);
`

export const ContactContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `}
`

export const Image = styled.img`
  max-height: 350px;
  max-width: 350px;
  flex-grow: 1;

  ${media.lessThan('small')`
    max-height: 200px;
    max-width: 200px;
  `}
`

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 5rem;

  ${media.lessThan('large')`
    margin-left: 2rem;
  `}

  ${media.lessThan('medium')`
    margin: 0;
  `}

  ${media.lessThan('small')`
    margin: 0;
  `}
`

export const FormTitle = styled.h2`
  margin-bottom: 1.4rem;
  margin-top: 2rem;

  color: rgb(67, 64, 104);

  ${media.lessThan('medium')`
    text-align: center;
  `}
`

export const FormFields = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const InputName = styled.input`
  height: 65px;
  border-radius: 4px;
  border: 0;
  padding: 0 1rem;

  flex: 1 1 356px;
  margin: 5px;
  font-size: 1.1rem;

  color: rgb(67, 64, 104);

  &::placeholder {
    color: rgb(67, 64, 104);
    font-style: italic;
  }

  ${media.lessThan('small')`
    flex: 1 1 100px;
  `}
`

export const InputEmail = styled.input`
  height: 65px;
  border-radius: 4px;
  border: 0;
  padding: 0 1rem;

  flex: 1 1 356px;
  margin: 5px;
  font-size: 1.1rem;

  color: rgb(67, 64, 104);

  &::placeholder {
    color: rgb(67, 64, 104);
    font-style: italic;
  }

  ${media.lessThan('small')`
    flex: 1 1 100px;
  `}
`

export const InputPhone = styled.input`
  height: 65px;
  border-radius: 4px;
  border: 0;
  padding: 0 1rem;

  flex: 1 1 356px;
  margin: 5px;
  font-size: 1.1rem;

  color: rgb(67, 64, 104);

  &::placeholder {
    color: rgb(67, 64, 104);
    font-style: italic;
  }

  ${media.lessThan('small')`
    flex: 1 1 100px;
  `}
`

export const InputCollection = styled.select`
  height: 65px;
  border-radius: 4px;
  border: 0;
  padding: 0 1rem;

  flex: 1 1 356px;
  margin: 5px;
  background: #fff;
  font-size: 1.1rem;

  color: rgb(67, 64, 104);
  font-style: italic;

  ${media.lessThan('small')`
    flex: 1 1 100px;
  `}
`

export const InputOption = styled.option`
  color: rgb(67, 64, 104);
  font-style: italic;

  &::selection {
    background-color: DodgerBlue;
  }
`

const rotate = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  background: #3ad686;

  width: 243px;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;

  font-size: 16px;
  color: #ffff;
  text-transform: uppercase;
  font-weight: bold;

  align-self: flex-end;
  transition: background 0.3s;

  > svg {
    animation: ${rotate} linear 1s infinite;
  }

  &:hover {
    background: ${darken(0.1, '#3ad686')};
  }

  ${media.lessThan('medium')`
    align-self: center;
    width: 100%;
  `}
`
