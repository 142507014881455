import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { darken } from 'polished'

export const HeaderWrapper = styled.div`
  ${media.greaterThan('1045px')`
    display: flex;

    max-width: 1480px;
    margin: 0 auto;
    padding: 0 2rem;
    height: 100%;
  `}
`

export const MenuPhone = styled.div`
  display: none;

  ${media.lessThan('1045px')`
    cursor: pointer;
    z-index: 6;
    display: flex;
    flex-direction: column;
    width: 33px;


    span {
      display: flex;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;

      background: #cdcdcd;
      border-radius: 3px;

      transform-origin: 4px 0px;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }

      ${({ menuExpanded }) =>
        menuExpanded &&
        css`
          &:nth-last-child(1) {
            opacity: 1;
            transform: rotate(-45deg) translate(-2px, -1px);
            background: #fff;
          }

          &:nth-last-child(2) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }

          &:nth-last-child(3) {
            transform: rotate(45deg) translate(0, -1px);
          }
        `}
    }
  `}
`

export const MenuWrapper = styled.div`
  ${media.lessThan('1045px')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;

    width: 100%;

    height: 5rem;
    background-color: #303049;
  `}
`

export const HeaderLeft = styled.div`
  z-index: 1;
`

export const ButtonContainer = styled.div`
  ${media.lessThan('1045px')`
      display: none
  `}
`

export const ImageLogo = styled.img`
  transition: height 0.4s;
  cursor: pointer;
  z-index: 5;

  ${media.lessThan('1200px')`
    height: 12rem;
  `}

  ${media.lessThan('1045px')`
    cursor: pointer;

    height: 5rem;
  `}
`

export const HeaderRight = styled.div`
  display: flex;
  z-index: 1;

  height: 100px;
  align-items: center;

  ${media.lessThan('1045px')`
    display: none;
  `}
`

export const HeaderLogo = styled.div`
  ${media.lessThan('1045px')`
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
  `}
`

export const HeaderTitle = styled.h1`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  color: rgb(67, 64, 104);
  margin-bottom: 26px;
  font-style: italic;

  ${media.lessThan('1045px')`
    display: none;
  `}
`

export const HeaderDescription = styled.h2`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  color: rgb(67, 64, 104);
  margin-bottom: 24px;
  font-style: italic;

  ${media.lessThan('1045px')`
    display: none;
  `}
`

export const SpotContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  overflow: none;
  height: 552px;

  transition: all 0.4s;
  transition: opacity 0.2s;

  ${media.greaterThan('1720px')`
    right: -50px;
  `}

  ${media.between('1630px', '1720px')`
    right: -150px;
  `}

  ${media.between('1045px', '1630px')`
    right: -300px;
  `}

  ${media.lessThan('1045px')`
    display: none;
  `}
`
export const PaintContainer = styled.div`
  position: absolute;
  top: -20px;
  right: 200px;
`

export const HeaderNav = styled.nav`
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
`
export const HeaderNavBuyNow = styled.span`
  padding: 0 2rem;
  cursor: pointer;

  transition: color 0.3s;

  &:hover {
    color: ${darken(0.3, '#fff')};
  }
`

export const HeaderNavColections = styled.span`
  border-left: 1px solid #fff;
  padding: 0 2rem;
  margin-right: 1rem;
  cursor: pointer;

  transition: color 0.3s;

  &:hover {
    color: ${darken(0.3, '#fff')};
  }
`
