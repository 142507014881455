import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const ModalWrapper = styled.div`
  transition: height 0.4s ease-in;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.menuPhone ? '100%' : '0')};
  background: #2f2d48;
  z-index: 5;
`
export const ModalOptionsList = styled.ul`
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  list-style: none;

  width: 100%;
  height: 100vh;

  justify-content: center;
  align-items: center;

  li {
    transition: opacity 0.6s ease-in-out;
    opacity: ${(props) => (props.menuPhone ? 1 : 0)};
  }
`

export const ModalOptionItem = styled.li`
  padding: 1rem 2rem;
  margin: 1rem 0;
  color: #fff;
  font-size: 2rem;

  border-radius: 120px;
  cursor: pointer;

  ${(props) =>
    props.spotlight &&
    css`
      background: linear-gradient(19.62deg, #fb28ff -39.68%, #284bff 94.47%);
    `}

  ${media.lessThan('small')`
    font-size: 1rem;
  `}
`
